@import '@core/styles/mixins/markdown-overrides';

.ReusableContentEditor {
  @include markdown-overrides(true);

  border-bottom: 1px solid var(--color-border-default);
  border-top: 1px solid var(--color-border-default);
  min-height: 0;
  overflow: auto;
  padding: var(--md) 30px;

  &_error {
    box-shadow: inset 0 0 0 1px var(--red);
  }

  &-formgroup {
    height: 100%;
  }
}
