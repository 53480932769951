.AppearanceNavigationDropdown {
  &-button {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid var(--color-border-default);
    font-size: 15px;
    height: unset;
    justify-content: space-between;
    padding: var(--sm) 15px;

    &:focus-visible {
      border-color: rgba(255, 255, 255, 0.30);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.20);
    }
  }

  &-menu {
    max-width: unset;
    width: 100%;
    width: stretch;
  }

  &-badge {
    margin-left: var(--xs);
  }
}
