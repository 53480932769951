@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.UserProfileCard {
    @include dark-mode {
        &-avatar {
            color: white;
        }

        &-text div:nth-child(2) {
            color: var(--gray80);
        }
    }

    min-height: 120px; // height of card if last active isn't present
    padding: 1em 0;

    &-avatar {
        display: flex;
        flex-direction: column;
        gap: var(--sm);
        padding: 0 !important;
        width: 100%;
    }

    &-text {
        align-items: center;
        display: flex;
        flex-direction: column;

        div:first-child {
            font-weight: var(--font-weight-bold);
        }
    }

    &-metaData {
        gap: 0;
        padding: var(--sm);
    }

    &-email {
        margin-top: var(--sm);
        width: 100%;
    }
}

