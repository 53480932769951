.Appearance {
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - var(--SuperHub-top-nav-height));
  position: absolute;
  right: 0;
  top: var(--SuperHub-top-nav-height);
  width: var(--SuperHub-aside-width);

  &-nav {
    border-bottom: 1px solid var(--color-border-default);
    box-sizing: border-box;
    padding: var(--sm) var(--SuperHub-margin) var(--SuperHub-margin);
  }
}
