.Preview {
  // `--color-primary`, etc are set by JS in the Theme component, so add some fallback values
  --Preview-primary-color: var(--color-primary, var(--blue));
  --Preview-primary-alt-color: var(--color-primary-alt, ##0265c0);
  --Preview-link-color: var(--color-link-primary, var(--blue));
  --Preview-header-link-color: #fff;

  $block: &;

  width: 100%;
}

.RequestHistoryPreview {
  $block: &;

  background: var(--white);
  overflow: hidden;
  width: 100%;
  border-radius: var(--border-radius-lg);

  &-container {
    transition: translate 400ms ease;
  }

  &-history {
    transition: opacity 400ms ease;
  }

  &:hover {
    #{$block} {
      &-container {
        translate: 0 calc(100% / -3.05);
        transition: translate 800ms ease 400ms;
      }

      &-history {
        opacity: 0;
        transition: opacity 800ms ease;
      }
    }
  }
}

:global(.ThemeContext_light) .Preview {
  --Preview-header-link-color: var(--gray20);
}
