.NavSection { 
  padding: 0;
  position: relative;

  &-toggle {
    height: var(--button-md);
    padding-left: var(--sm);
    width: 100%;

    > :global(.FormGroup-inputs) {
      justify-content: center;
    }
  }

  &-button {
    position: absolute;
    right: var(--sm);
    top: var(--sm);
    z-index: 2;
  }

  &-icon {
    transition:  transform var(--transition-fast);

    &_open {
      transform: rotate(90deg);
    }
  }

  &-alias {
    border-top: 1px solid var(--color-border-muted);
    padding: var(--sm);

    &-label {
      align-items: center;
      color: var(--color-text-muted);
      display: flex;
      min-width: 50px;
  
      label {
        font-size: 13px;
        font-weight: var(--font-weight);
        justify-content: center;
        padding: 0 var(--sm);
      }
    }
  }

  &-locked {
    border-top: 1px solid var(--color-border-default);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
