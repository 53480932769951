.BestPractices {
  border-radius: var(--border-radius-lg);
  margin-bottom: var(--md);

  &-heading {
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: var(--md);
    margin: 0;
  }

  &-description {
    font-size: 12px;
    line-height: 1.4;
    margin-top: var(--xs);
    max-width: 60ch;
  }

  &-list {
    color: var(--color-text-muted);
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 8px;
    line-height: 1.4;
    margin: 0;
    padding: 0 var(--md);

    + .BestPractices-card-heading {
      margin-top: var(--md);
    }
  }

  &-link {
    color: var(--blue50);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-code,
  &-code_block {
    background-color: var(--gray10);
    border-radius: 3px;
    color: white;
    font-size: 10px;
    line-height: 15px;
  }

  &-code {
    display: inline-block;
    padding: 0 var(--xs);
    word-break: break-all;
  }

  &-code_block {
    display: block;
    margin: var(--xs) 0 0;
    padding: var(--xs) 8px;
    width: 100%;
  }

  &-pre {
    margin: 0;
    overflow: auto;
  }

  &-card {
    background: var(--gray10);
    border: 0;
    padding: 15px;

    &-heading {
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      line-height: 14px;
      margin: 0 0 var(--xs);
    }

    &-column {
      &:last-child {
        margin-top: var(--md);
      }
    }
  }
}
