@import '@core/styles/vars';

.Segments {
  $root: &;

  flex: 0 0 auto;
  overflow-y: auto;

  &_superhub {
    flex: 1;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */

    // Hide scrollbar when in Superhub panel since it will sit flush within BottomBar
    #{$root}-segmentRow {
      &::-webkit-scrollbar {
        background: transparent;
        height: 0;
  
        // chrome
        width: 0;
      }
    }
  }

  &-segmentRow {
    overflow-x: auto;
  }

  &-segmentPill {
    max-width: 350px;
  }

  &-segmentTitle {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-smallSaveButton,
  &-segmentEmoji,
  &-segmentIcon {
    flex-shrink: 0;
  }

  &-smallSaveButton {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    display: flex;
    font-size: 12px;
    height: 20px;
    padding: 0 6px;
    text-transform: uppercase;
  }

  &-inputMenuItem {
    gap: var(--xs);

    :global(.Dropdown) {
      flex: 1;
    }
  }

  &-lastEditedMenuItem {
    color: var(--gray40);
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  &-add-segment {
    position: relative;

    &::before{
      background-color: var(--gray30);
      border-radius: var(--border-radius);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 1px;
    }
  }
}
