@import '~@core/styles/vars';

.SetupBanner {
  max-height: 100vh;
  overflow: hidden;
  position: relative;

  &-preview {
    bottom: var(--md);
    position: fixed;
    right: var(--md);
  }

  &-card {
    backdrop-filter: blur(0);
    background: linear-gradient(
      rgba(var(--green100-rgb), 0%),
      rgba(var(--green100-rgb),  95%),
      rgba(var(--green100-rgb), 100%)
    );
    border: 0;
    border-radius: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: flex-end;
    margin-left: -20px;
    overflow: hidden;
    padding: var(--md);
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  &-content-title {
    display: flex;
    flex-direction: column;
    gap: var(--sm);
    pointer-events: all;
    width: 450px;
  }

  &-title {
    color: var(--color-text-default);
    font-family: Marfa, sans-serif;
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    margin: 0;
  }

  &-description{
    color: var(--color-text-minimum);
    font-size: 14px;
  }

  &-grid {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: var(--md);

    &-item-title-container {
      align-items: center;
      display: flex;
      gap: var(--xs);
    }

    &-item-title {
      align-items: center;
      background-color: var(--gray10);
      background-image: radial-gradient(76% 151% at 52% -52%, rgba(var(--green-rgb), 0.23) 0%, transparent 100%);
      border: 0;
      border: 1px solid rgba(black, 0.1);
      border-radius: var(--sm);
      box-shadow:
        inset 0 1px 0 rgba(white, 0.2),
        inset 0 -1px 0 rgba(black, 0.1);
      color: var(--green);
      display: flex;
      font-size: 14px;
      font-weight: bold;
      justify-content: space-between;
      padding: var(--sm);
      transition: all 150ms ease-in-out;
      width: 400px;

      &:hover {
        background-image: radial-gradient(76% 151% at 52% -52%, rgba(var(--green-rgb), 0.6) 0%, transparent 100%); // Increased opacity for stronger effect
        color: var(--green);
        filter: brightness(1.1);
      }

      &:active {
        transform: scale(.95);
      }
    }

    &-item-title-icon {
      opacity: .5;
    }

    &-item-title-time {
      font-size: 12px;
      font-weight: var(--font-weight-medium);
      margin-left: var(--xs);
      opacity: .5;
    }

    &-item-title:hover &-item-arrow {
      display: block;
      opacity: 1; // Fully visible on hover
      visibility: visible; // Make it interactable
    }

    &-item-title-languages {
      display: flex;
      flex-direction: row;
      gap: var(--xs);
    }
  }

  &-include-try-it {
    border-top: 1px solid var(--color-border-default);
    color: var(--color-text-minimum);
    font-size: 12px;
    margin-top: 30px;
    padding: var(--md) 0;
  }

}
