.PreviewRow {
  &-layout {
    display: flex;
    flex-wrap: wrap;
    gap: var(--md);
    justify-content: flex-start;

    > * {
      flex: 0 0 100px;
    }

    @container page-content (max-width: 464px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }
  }
}
