.LaunchButton {
  &-button {
    background-color: var(--purple30);
    border: 0;
    box-shadow: 0 0 0 1px inset rgba(255, 255, 255, 0.1);
    font-size: 13px;
    font-weight: var(--font-weight-bold);
    height: 24px;
    margin-right: var(--xs);
    width: inherit;

    &:hover,
    &:active {
      background-color: var(--purple20);
    }

    &:active,
    &:focus-visible {
      box-shadow: 0 0 0 1px inset rgba(255, 255, 255, 0.1), 0 0 0 3px rgba(var(--purple40-rgb), .25);
    }
  }

  &-menu {
    border-radius: var(--border-radius);
    width: 240px;


    &-body {
      font-size: 14px;
      line-height: 1.4;
      margin: 0;
      padding: 15px;
    }

    &-footer {
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: grid;
      gap: var(--sm);
      grid-template-columns: auto 1fr;
      padding: 7px  15px;

      &-text {
        font-size: 12px;
        line-height: 1.3;
        margin: 0;
      }
    }
  }
}