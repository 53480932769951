.WebhookEnterpriseConfig {
  &-section {
    border-bottom: 1px solid var(--color-border-default);
    padding: var(--md) 0;

    &-description {
      font-size: 14px;
      padding-bottom: var(--sm);
      padding-top: var(--xs);
    }
  }

  &-card {
    &-permission-icon {
      color: var(--gray70);
    }

    &-permission-text {
      color: var(--color-text-minimum);
      font-size: 12px;
    }
  }
}
