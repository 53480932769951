.AllPages {
  &-description {
    color: var(--color-text-muted);
    font-size: 12px;
    font-weight: var(--font-weight);
    margin-top: 0;
  }
    
  &-fieldset {
    legend {
      margin-bottom: var(--sm) !important;
    }
  }
}
