.ErrorPages {
  $block: #{&};

  &-redirects {
    &-description {
      color: var(--color-text-muted);
      font-size: 12px;
      font-weight: var(--font-weight);
      line-height: 1.4;
      margin: var(--xs) 0 0;

      code {
        background: var(--color-input-background);
        padding: 1px 2px;
        white-space: nowrap;
      }
    }

    &-input {
      font-family: var(--font-family-mono);
      font-size: 14px;
    }
  }
}
