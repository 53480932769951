.WebhookTestForm {
  &-try-it-container {
    display: grid;
    gap: var(--sm);
    grid-auto-flow: column;
    grid-template-columns: 1fr 80px;
  }

  &-definition {
    font-size: 12px;

    &-term {
      color: var(--color-text-minimum);
      margin-right: var(--sm);
    }
  }
}
