.Page {
  --Page-actions-height: 62px;

  box-sizing: border-box;
  color-scheme: dark;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow: auto;

  &-fill-height-container {
    display: grid;
    min-height: calc(100% - var(--Page-actions-height)); // 62px is the height of the .Page-actions element
  }

  &-actions {
    backdrop-filter: blur(var(--md));
    background-color: rgba(var(--gray0-rgb), 0.15);
    border-top: 1px solid var(--color-border-default);
    bottom: 0;
    box-sizing: border-box;
    height: var(--Page-actions-height);
    padding: var(--sm);
    position: sticky;
    width: 100%;
    z-index: var(--z-toolbar);
  }

  :global(.Tabs-list) {
    --Tabs-color-active: var(--blue);

    border-bottom-color: var(--color-border-default);
    padding: 5px 15px 0;
  }

  :global(.Tabs-listItem) {
    --Tabs-color-active: var(--blue);

    font-size: 16px;
    font-weight: var(--font-weight);
  }  
}
