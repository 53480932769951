@import '@core/styles/vars';

.MetricsBanner {
  gap: var(--sm);
  width: 100%;

  :global(.icon-api-metrics) {
    margin-right: 0;
  }

  &-ctaButton {
    background: transparent;
    border-color: var(--border-color);
    color: currentcolor;

    &:not(:disabled) {
      &:hover,
      &:active,
      &:focus {
        border-color: var(--yellow30);
        box-shadow: 0 0 0 3px rgba($yellow10, 0.25);
        color: currentcolor;
      }
    }

    &_alert {
      border-color: rgba(255, 255, 255, 0.5);

      &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
          border-color: rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
}
