.ProjectMenuDropdown {
  min-width: 14px;
  width: 100%;
}

.ProjectMenu {
  &-header {
    box-sizing: border-box;
    display: grid;
    gap: 10px;
    padding: 0 var(--sm) var(--xs);
    width: 240px;
  
    &-project {
      font-weight: var(--font-weight);
      margin: 15px 0 10px;
      text-align: center;
    }
  
    &-name {
      font-size: 14px;
      font-weight: var(--font-weight-bold);
      line-height: 1.3;
      text-wrap: balance;
    }

    &-label {
      color: var(--color-text-minimum);
      font-size: 10px;
      letter-spacing: 0.04em;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

  &-avatar {
    border-radius: var(--border-radius);
    height: 30px;
    object-fit: contain;
    padding: var(--xs);
  }

  &-group-link {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    color: var(--color-text-default);
    display: grid;
    font-weight: var(--font-weight-bold);
    padding: 8px var(--sm);
    text-decoration: none;
    transition: background-color var(--transition-fast);
    width: 100%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.075);
      color: var(--color-text-default);
    }

    &-eyebrow {
      color: var(--color-text-minimum);
      font-size: 11px;
    }

    &-name {
      display: grid;
      font-size: 13px;
      gap: var(--xs);
      grid-template-columns: 1fr auto;
    }

    &-icon {
      color: var(--color-text-minimum);
      font-size: 14px;
    }
  }

  &-godmode-button {
    --button-gap: 2px;
    --button-icon-offset: -2px;

    background: linear-gradient(180deg, var(--yellow70) 0%, var(--yellow) 100%);
    border-color: rgba(black, 0.2);
    box-shadow:
      0 1px 8px 0 rgba(var(--yellow-rgb), 0.10),
      0 2px 4px 0 rgba(black, 0.05),
      0 1px 2px 0 rgba(black, 0.05),
      0 -1px 2px 0 rgba(black, 0.1) inset,
      0 1px 0 0 rgba(white, 0.25) inset;
    filter: saturate(1.25);
    font-size: 11px;
    transition: all var(--transition-fast);
    
    :global(.IconWrapper) {
      font-size: 12px;
    }

    &,
    &:hover,
    &:focus,
    &:visited {
      background: linear-gradient(180deg, var(--yellow70) 0%, var(--yellow) 100%);
      color: var(--yellow10);

      :global(.IconWrapper) {
        color: var(--yellow20);
      }
    }

    &:hover,
    &:focus-visible {
      border-color: rgba(black, 0.3);
      filter: saturate(2);
    }
  }
}
