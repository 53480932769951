@import '@core/styles/vars';

.ManageWebhookVariables {
  &-variable-sections {
    width: 100%;

    &-item {
      border-bottom: 1px solid var(--color-border-default);   
      display: grid;
      gap: 10px 0;
      grid-template-columns: 180px auto;
      padding: var(--md) 0;

      @media (max-width: $dash-container-lg) {
        grid-template-columns: 1fr;
      }

      &-header {
        &-description {
          color: var(--semantic-text-muted);
          display: inline-flex;
          flex-wrap: wrap;
          font-size: 12px;
          line-height: 1.4;
          margin: 5px 0 10px;
        }

        &-title {
          margin: 0;
        }
      }
    }

     // Hide the border on the last item
     &-item:last-of-type {
      border-bottom: 0;
    }
  }

  &-variables-section-tooltip {
    $block: &;

    align-items: center;
    cursor: pointer;
    display: inline-flex;
    gap: 3px;

    &-icon {
      opacity: 0.5;
    }

    &:hover,
    &:active,
    &:focus {
      #{$block}-icon {
        opacity: 1;
      }
    }
  }
}
