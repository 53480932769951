.WebhookCodeSnippet {
  --WebhookCodeSnippet-bg: var(--gray0);
  --WebhookCodeSnippet-highlight-bg: black;

  background: var(--WebhookCodeSnippet-bg);
  color: white;
  height: 100%;
  min-height: 0;
  min-width: 0;

  &_newSetup {
    --WebhookCodeSnippet-bg: var(--gray10);

    background: var(--WebhookCodeSnippet-bg);
    border-radius: var(--border-radius);
    overflow: hidden;
  }

  &-header {
    padding: var(--sm) var(--sm) var(--xs);
  }

  &-subheader {
    border-top: 1px solid var(--color-border-default);
    margin-top: var(--xs);

    &_hidePicker {
      border-top: 0;
      margin-top: 0;
    }
  }

  &-library-options {
    padding: var(--xs);
  }

  &-option-row {
    width: 100%;
  }

  &-lambda-button {
    text-transform: none;
  }

  &-provision-toggle {
    margin-bottom: 0;
  }

  &-footer {
    padding: var(--sm);
  }

  &-dropdown-btn {
    --button-font-size: 10px;
  }

  &-menu-header {
    text-transform: uppercase;
  }

  // styled to match a size="xs" button
  &-client-label {
    align-items: center;
    color: var(--gray70);
    display: inline-flex;
    font-size: 10px;
    font-weight: var(--font-weight-bold);
    height: var(--md);
    letter-spacing: 0.05em;
    padding: 2px 7px 0;
    text-transform: uppercase;
  }

  &-copy {
    border: 0;
    color: var(--gray60);
    height: 40px;
  }

  &-languagePicker :global(.Button) {
    span,
    & > i:last-child {
      &,
      &:hover {
        color: white;
      }
    }
  }

  :global {
    pre {
      // unset some global bootstrap.css styles from the dash bundle
      background-color: unset;
      border: unset;
      border-radius: unset;
      color: unset;
      display: block;
      font-size: unset;
      line-height: unset;
      margin: unset;
      padding: unset;
      word-break: unset;
      word-wrap: unset;
    }

    .CodeSnippet {
      color-scheme: dark;
      height: 100%;
      min-height: 0;
      white-space: pre;
    }

    .CodeEditor {
      line-height: 1.4;

      .CodeMirror {
        color-scheme: dark;
        display: grid;
        font-size: 11px;
        overflow: auto;
        padding: var(--sm) 0;
        white-space: nowrap;
        width: 100%;

        &,
        .CodeMirror-gutters {
          background-color: var(--WebhookCodeSnippet-bg) !important;
        }

        .cm-linerow {
          padding-right: var(--sm);
        }

        .cm-lineNumber {
          background: var(--WebhookCodeSnippet-bg);
          left: 0;
          position: sticky;
          text-indent: var(--sm);
        }

        .cm-highlight,
        .cm-highlight .cm-lineNumber {
          background-color: var(--WebhookCodeSnippet-highlight-bg);
        }
      }
    }
  }
}
