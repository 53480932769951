.Changelog {
  &-date {
    box-sizing: border-box;
    color: var(--color-text-default);
    display: flex;
    font-size: 12px;
    font-weight: var(--font-weight);
    justify-content: center;
    padding: var(--xs);
    width: 92px;
  }
}
