.ComponentEditor {
  border-bottom: 1px solid var(--color-border-default);
  border-top: 1px solid var(--color-border-default);
  min-height: 0;
  overflow: hidden;

  :global {
    .FormGroup,
    .FormGroup-inputs,
    .FormGroup-input {
      height: 100%;
    }

    .CodeEditor {
      padding-right: 0;

      .CodeMirror,
      .cm-s-neo.CodeMirror {
        &,
        .CodeMirror-gutters {
          background-color: var(--black) !important;
        }
      }
    }

    .FormGroup {
      border: 1px solid transparent;
    }

    .FormGroup_error {
      border-color: var(--red);
      box-sizing: border-box; // prevents bottom border from being cut off by footer
    }

    .FormGroup-input {
      grid-auto-rows: auto;
    }
  }

  &-inputWrapper {
    flex-basis: 50%;
    height: 100%;
    min-height: 0;
    width: 100%;
  }

  &-codeInput {
    background: var(--black);
    border: 0;
    padding-top: 0;
    resize: none;

    &:focus-within {
      box-shadow: none;
    } 
  }

  &-preview {
    background: var(--gray0);
    border-radius: 12px 0 0 12px;
    flex-basis: 50%;
    height: 100%;
    transition: background var(--transition-slow);
    width: 100%;

    &_col {
      border-radius: 12px 12px 0 0;
      max-height: 50%;
    }

    &_row {
      max-width: 50%;
    }

    // When we display the preview in light mode, we need to override the SuperHub 
    // data-color-mode=dark styles manually with theme.scss base values
    // We may need to add more style attributes in the future
    &_light {
      --color-text-default: var(--gray20);
      --color-bg-page: var(--white);
    
      background: var(--color-bg-page);
      color: var(--color-text-default);

      :global {
        .Button_contrast_ghost {
          color: var(--gray20);

          &:not(:disabled):hover {
            border-color: #{rgba(black, 0.2)};
            color: var(--gray20);
          }
        }
      }
    }

    &-header {
      padding: var(--xs) var(--sm);

      :global {
        .Badge_dark {
          background: var(--black);
        }
      }
    }

    &-render {
      height: 100%;
      overflow: auto;
      padding: 1em;

      :global(.markdown-body) {
        height: 100%;
        width: 100%;
      }
    }
  }
}
