.ProjectNavigationBreadcrumb {
  &_leaf-segment-only {
    margin-left: var(--xs);
  }

  &-leaf-segment::before {
    background-color: var(--gray30);
    content: '';
    display: block;
    height: 18px;
    left: -10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(15deg);
    width: 1.5px;
  }

  &-version-menu-item {
    display: grid;
    grid-template-columns: 1fr auto auto;

    &:not(:global(.Menu-Item_active)) {
      padding-right: 36px;
    }

    &:global(.Menu-Item_active::after) {
      padding-left: var(--sm);
    }

    &-main {
      color: var(--color-text-minimum);
      font-size: 12px;
    }
  }
}
