.WebhooksErrorModal {
  height: 520px;
  min-width: 900px;

  &-body {
    display: flex;
  }

  &-mirror {
    border-radius: var(--border-radius-lg);
    display: flex;
    flex: 1;
    overflow: clip;
    width: 480px;
  }

  &-error {
    border-left: 1px solid var(--color-border-default);
    flex: 0 0 320px;
    padding-left: var(--md);

    &-section {
      &-title {
        color: var(--color-text-default);
        font-size: var(--md);
        font-weight: var(--font-weight);
        margin-bottom: 0;
      }

      &-description {
        color: var(--color-text-muted);
        font-size: 16px;
        line-height: 1.4;
        margin: 15px 0;
      }
    }

    &-footer {
      align-self: flex-end;
      border-top: 1px solid var(--color-border-default);
      padding: var(--md) 0 0;
      width: 100%;
    }
  }
}
