@import '@core/styles/vars';

.MetricsSearch {
  --MetricsSearch-width: 250px;

  width: var(--MetricsSearch-width);

  &_wide {
    --MetricsSearch-width: 400px;

    width: var(--MetricsSearch-width);
  }

  &_superhub {
    :global(.InputParent-clearBtn) {
      background: transparent;

      &:hover {
        background: transparent;
        color: var(--color-text-minimum);
      }
    }
  }

  &-loading,
  &-no-results {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }

  &-input,
  &-menu,
  &-loading,
  &-no-results {
    width: var(--MetricsSearch-width);
  }

  &-no-results-text {
    color: var(--color-text-minimum);
  }

  &-input {
    border-radius: 40px;

    // hide default search style since it applies an "X" behind our custom clear button
    &::-webkit-search-cancel-button {
      appearance:none;
    }

    &_superhub {
      background: #{rgba(white, 0.10)};
    }
  }

  &-menu {
    max-width: unset;
  }

  &-menu-item {
    $block: &;

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-link-icon {
      opacity: 0;
      transform: translateX(-3px);
      transition:
        opacity var(--transition-fast) var(--transition-timing),
        transform var(--transition-fast) var(--transition-timing);
    }

    &:not(#{$block}_disabled) {
      &:hover {
        #{$block}-link-icon {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  &-value {
    color: var(--color-text-muted);
    flex: 0 0 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-details {
    color: var(--color-text-muted);
    font-weight: var(--font-weight-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &-units,
  &-email,
  &-timestamp {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-units {
    color: var(--color-text-minimum);
    font-size: 12px;
  }

  &-email,
  &-timestamp {
    font-size: 14px;
  }

  &-date-menu-item {
    :global(.Flex) {
      align-items: center;
    }
  }
}
