.VersionsList {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;

  &Item { 
    $item-root: &;

    align-items: center; 
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    display: flex;

    &_current {
      background: rgba(var(--white-rgb), 0.15);
      border-color: var(--color-border-default);
    }

    &:not(#{$item-root}_current):focus-within,
    &:not(#{$item-root}_current):hover {
      background: rgba(var(--white-rgb), 0.10);
      border-color: var(--color-border-default);
    }

    &-link {
      align-items: center;
      color: var(--color-text-default);
      display: flex;
      font-size: 13px;
      font-weight: var(--font-weight);
      gap: 3px;
      height: 100%;
      line-height: 14px;
      padding-left: var(--sm);
      text-decoration: none;
      width: 100%;

      &-icon-container {
        color: var(--color-text-minimum);
        display: flex;
        width: 15px;
      }
    }

    &-edit-button {
      height: 100%;
      opacity: 0;
      padding-right: var(--sm);

      // if the parent item is hovered or has a child in focus, make the edit
      // button visible
      :focus-within > &, 
      :hover > & {
        opacity: 1;
      }
    }
  }
}
