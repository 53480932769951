@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.UserMetric {
    @include dark-mode {
        color: white;

        &-title, &-value {
            color: var(--gray80);
        }

        &-label {
            color: white;
        }
    }

    border-top: 1px solid var(--color-border-default);
    font-size: 14px;
    font-weight: var(--font-weight);
    min-height: 64px; // height of area with only error text showing
    padding: 0 var(--sm);

    &-title {
        color: var(--gray40);
        font-size: 12px;
        margin-top: var(--sm);
    }

    &-btn {
        margin-bottom: var(--xs);
        padding: 0;
    }

    &-label {
        color: var(--gray20);
        margin-right: var(--xs);
        min-width: 0;

        &-icon-right{
            flex-direction: row-reverse;
        }
    }

    &-value {
        color:var(--gray40);
    }

    &-row {
        margin-bottom: var(--sm);
    }
}
