@import '@core/styles/vars';

.TopBar {
  display: grid;
  gap: var(--sm);
  grid-template-columns: 1fr 1fr 1fr;

  &-title {
    display: flex;
    font-size: 14px;  
    gap: var(--sm);
    margin-top: 0;
    position: relative;

    &-breadcrumb {
      --breadcrumb-separator-width: 1.5px;

      align-items: center;
      display: flex;
      gap:var(--xs);
      margin-left: calc(var(--sm) + var(--breadcrumb-separator-width));
      position: relative;
      
      &::before{
        background-color: var(--gray30);
        content: "";
        display: block;
        height: 18px;
        left: -10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(15deg);
        width: var(--breadcrumb-separator-width);
      }
    }
  }

  &-insights-companyLogo {
    height: 12px;
    width: 12px;
  }

  &-insights-company {
    font-size: 14px;
  }

  &-actions {
      margin-left: auto;
  }
}
