.NavListItem {
  align-items: stretch;
  background-color: var(--gray10);
  border-radius: var(--border-radius-lg);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  overflow: visible;
  padding: var(--sm);
  position: relative;
  transition: background-color var(--transition-fast);

  &_interactive {
    cursor: pointer;
  }

  &_interactive:not(.NavListItem_open):hover {
    background-color: var(--gray15);
  }

  &_open {
    cursor: default;
    gap: var(--sm);
  }
}
