.CustomPagePicker {
  // This is meant to be used within an InputGroup, but the Inputs are not direct children of
  // the InputGroup, so subtract borders from the height.
  --CustomPagePicker-height: calc(var(--button-sm) - 2px);

  &-toggle {
    align-items: center;
    background: var(--color-input-background);
    box-sizing: border-box;
    color: var(--color-input-text);
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 14px;
    height: var(--CustomPagePicker-height);
    justify-content: space-between;
    padding: 0;
    padding-left: var(--sm);
    padding-right: var(--sm);
    width: 100%;

    &_placeholder {
      color: var(--color-input-placeholder);
    }
  }

  &-input {
    height: var(--CustomPagePicker-height);

    &-wrapper {
      --Input-suffix-width: 56px;
    }
  }

  &-input,
  &-toggle {
    border: 1px solid transparent;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;

    &:focus-visible {
      border: 1px solid var(--blue);
      box-shadow: 0 0 0 3px rgba(var(--blue-rgb), 0.25);
      outline: none;
    }
  }

  &-menu {
    max-width: unset;
    pointer-events: auto;
  }

  &-spinner {
    margin-right: var(--xs);
  }
}
