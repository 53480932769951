@import '@core/styles/vars';

.SidebarNavLink {
  $block: &;

  list-style: none;

  &-link {
    align-items: center;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    color: var(--color-text-default);
    cursor: pointer;
    display: flex;
    font-size: 13px;
    gap: var(--sm);
    padding: var(--xs);
    text-decoration: none;
    transition: background var(--transition-fast), color var(--transition-fast), border-color var(--transition-fast);
    user-select: none;

    &:hover {
      background: #{rgba(white, 0.08)};
      color: currentcolor;
    }

    &_active,
    &_active:hover {
      background: #{rgba(white, 0.15)};
      border-color: var(--color-border-default);
    }
  }

  &-icon,
  &-external-icon {
    color: var(--color-text-minimum-icon);
  }

  &-external-icon {
    transition: color var(--transition-fast), transform var(--transition-fast);
  }

  &:hover #{$block}-external-icon {
    color: var(--color-text-minimum);
    transform: translate(1px, -1px);
  }

  &-badge {
    margin-left: auto;

    + #{$block}-badge {
      margin-left: 0;
    }
  }
}
