.CustomBlockForm {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  padding: 0;

  &-controls,
  &-footer {
    display: flex;
    justify-content: space-between;
    padding: var(--sm);
  }

  &-controls {
    align-items: center;
  }

 

  &-controls-config-btn {
    --button-gap: var(--sm);

    &-name {
      color: var(--color-text-default);
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &_tag {
        font-family: var(--font-family-mono);

        &::before {
          content: '<';
          opacity: 0.5;
        }

        &::after {
          content: '/>';
          opacity: 0.5;
        }
      }
    }

    &-empty {
      color: var(--color-text-minimum);
    }
  }

  &-controls-inline {
    display: flex;

    &-input {
      border-color: transparent;
      color: var(--color-text-default);
      font-weight: var(--font-weight-bold);
      width: 400px;

      &:focus {
        border-color: var(--Input-border-active);
      }
    }
  }
}
