@import '~@core/styles/vars';

.MyDevelopersUsers {
  &-layout {
    display: grid;
    gap: 15px;
    grid-template-columns: 250px minmax(auto, 1fr);
    margin: 1rem 0;
  }

  &-page {
    display: flex;
    flex-flow: nowrap column;
    gap: 1em;
    overflow: auto;
  }
}
