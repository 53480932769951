@import '@core/styles/vars';

.DemoBanner {
    background: var(--green80);
    border-bottom: 1px solid var(--green);
    color: var(--green20);
    font-weight: 500;
    overflow: hidden;
    position: relative;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: var(--z-banner);
}
