.CustomCode {
  $block: &;

  &-code-input {
    height: 100%;
    max-height: 500px;
    min-height: 380px;

    &_minimal {
      height: 380px;
    }

    &-container {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-rows: min-content;
      position: relative;
    }
  }

  &-image-upload {
    margin-top: var(--sm);

    &-success {
      background: var(--color-bg-page);
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      bottom: 0;
      box-sizing: border-box;
      color: var(--color-text-default);
      cursor: default;
      font-size: 13px;
      opacity: 0.8;
      padding: var(--xs);
      position: absolute;
      width: 100%;

      &[hidden] {
        opacity: 0;
        transition: opacity 1s linear, visibility 0s linear 1s;
        visibility: hidden;
      }
    }

    &-scroll {
      all: unset;
      color: inherit;
      cursor: pointer;
      font-size: inherit;
      margin-left: var(--xs);
      text-decoration: underline;
    }
  }
}
