.PayGate {
  height: 100%;
  isolation: isolate;
  min-height: 0;
  position: relative;
  width: 100%;
  
  &-content {
    height: 100%;
    overflow: hidden;
  }

  &-overlay {
    inset: 0;
    padding: var(--md);
    position: absolute;
    z-index: var(--z-modal);

    &::before {
      background-color: var(--color-bg-page);
      content: '';
      display: block;
      inset: 0;
      opacity: 0.75;
      position: absolute;
      top: 0;
    }

    &_disabled {
      cursor: not-allowed;
    }
  }

  &-banner {
    border-radius: var(--border-radius-lg);
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10), 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    max-width: 400px;
    min-height: 195px;
    position: relative;
    width: 100%;
    z-index: calc(var(--z-modal) + 1);

    &_image {
      min-height: 360px;
    }
  }

  &-body {
    padding: var(--md);
  }

  &-image {
    margin-bottom: var(--sm);
    width: 100%;
  }

  &-title,
  &-description {
    text-align: center;
    text-wrap: balance;
  }

  &-title {
    font-size: 22px;
    font-weight: var(--font-weight-bold);
    line-height: 1.4;
    margin: var(--xs) auto ;
  }

  &-description {
    color: var(--purple90);
    line-height: 1.4;
    margin: 0;
  }

  &-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: var(--sm);
  }
}
