@import '@core/styles/vars';

.SidebarNav {
  border-right: 1px solid var(--color-border-default);
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: var(--sm);

  &-content-wrapper {
    height: 100%;
    position: relative;
    transition: translate var(--transition-slow) ease;
    translate: 0;
    width: 100%;
  }

  &-content {
    height: 100%;
    overflow: hidden auto;
    padding-top: var(--sm);
    visibility: visible;

    &[hidden] {
      overflow: visible;
      transition: visibility 0s linear var(--transition-slow);
      visibility: hidden;
    }
  }
}
