.PanelContent {
  &-container {
    font-size: 13px;

    a {
      color: var(--color-text-muted);
      text-decoration: underline;
    }

    label {
      font-weight: var(--font-weight);
      margin: var(--sm) 0 0;
    }

    > h1 {
      font-size: 18px;
      margin: 0;
    }

    > p {
      color: var(--color-text-muted);
      margin: 0;
    }

    dl {
      line-height: 1.5;
      margin: var(--xs) 0 0;
    }

    dt {
      display: inline-block;
      font-weight: var(--font-weight-normal);
      margin-left: var(--sm);
    }

    dd {
      display: inline;
      margin: 0;

      &::after {
        content: '\a';
        white-space: pre;
      }
    }
  }
}
