.NavListFieldGroup {
  &-collapsible_open {
    // Allow dropdowns to overflow the bounds of item
    overflow: visible;
  }

  &-toggle {
    background: transparent;
    border: 1px solid transparent;
    color: inherit;
    cursor: pointer;
    font: inherit;
    font-weight: var(--font-weight);
    height: 100%;
    left: 0;
    line-height: normal;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &:focus-visible {
      border: 1px solid var(--blue);
      box-shadow: 0 0 0 3px rgba(var(--blue-rgb), 0.25);
      outline: none;
    }

    &-label {
      align-items: center;
      display: flex;
      margin-right: auto;
      padding-left: var(--xs);
    }
  }

  &-controls {
    position: relative;
    z-index: 2;
  }

  &-input-group-label {
    align-items: center;
    color: var(--color-text-muted);
    display: flex;
    min-width: 50px;

    label {
      font-size: 13px;
      font-weight: var(--font-weight);
      justify-content: center;
      padding: 0 var(--sm);
    }
  }

  &-empty {
    padding-left: var(--sm);
    padding-right: 3px;
  }

  :global {
    .FormGroup + .FormGroup {
      margin-top: 0;
    }

    .FormGroup-input {
      margin-bottom: 0;
    }

    .FormGroup-messages {
      margin-left: 52px;
    }
  }
}
