.WebhookInstructions {  
  &-code-snippet {
    height: auto;
    width: 100%;
  }

  &-ngrok-instructions {
    margin-bottom: var(--md);
  }

  &-section {
    margin-top: var(--md);
    width: 100%;


    &-title {
      font-size: 14px;
      font-weight: var(--font-weight-bold);
    }

    &-description {
      font-size: 14px; 
      margin: 0 0 var(--sm);
    }
  }
}
