.BasePanel {
  height: 100%;
  min-height: 0;

  &-root {
    color: var(--color-text-default);
    color-scheme: dark;
    height: 100%;
  }
}
