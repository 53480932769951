@import '@core/styles/vars';

.MyDevelopersTitle {
  &-title {
    white-space: nowrap;
  }

  &-user {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    margin-top: var(--xs);

    :global(.Icon) {
      color: var(--color-text-minimum-icon);
    }
  }

  &-insights-error {
    font-size: 14px;
  }

  &-insights-companyLogo {
    height: 12px;
    width: 12px;
  }

  &-insights-requests {
    padding-left: var(--sm);
  }

  &-insights-company {
    font-size: 12px;
  }

  &-insights-requests,
  &-insights-userAgents {
    color: var(--color-text-minimum);
    font-size: 12px;
  }

  &-email {
    color: var(--color-text-default);
    font-size: var(--md);
    margin: 0
  }
}
