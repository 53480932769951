@import '@core/styles/vars';

.MetricsPage {
  &-graph {
    &Wrap {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }

  &-subheading {
    &-url {
      font-weight: var(--font-weight-normal);
    }
  }

  &-delta {
    font-size: 12px;

    &_comparison {
      color: var(--gray60);
      display: flex;
      gap: 5px;
    }
  }

  &-vs {
    margin: 0 0.2em;
    padding: 0 0.4em;
  }

  &-limit {
    bottom: 3em;
    position: fixed;
    right: 5em;
  }
}
