/* stylelint-disable no-descending-specificity */
@import '@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Setup {
  a:not(:global(.Button)) {
    color: inherit;
    font-weight: normal;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  &-page {
    --Setup-bg: var(--gray100);

    @include dark-mode {
      --Setup-bg: var(--gray10);

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        --Setup-bg: rgb(36, 46, 52);
      }
    }

    background: var(--Setup-bg);
    min-height: 100vh;
    width: 100%;
  }

  &-content-ctn {
    padding-bottom: var(--lg);
    padding-top: var(--lg);
  }

  &-card {
    margin: var(--xl) auto 0;
    max-width: 900px;
    padding: var(--md);

    &-title {
      margin-top: 0;
    }

    &-text {
      color: var(--color-text-minimum);
      font-size: 13px;
    }

    &-actions {
      flex-wrap: wrap;

      :global(.IconWrapper) {
        margin-right: 3px;
      }
    }

    &-status {
      color: var(--color-text-minimum);
      font-size: 10px;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      white-space: nowrap;
    }

    &-permission-icon {
      color: var(--gray70);
    }

    &-permission-text {
      color: var(--color-text-minimum);
      font-size: 12px;
    }

    &-help {
      align-items: center;
      color: var(--color-text-minimum);
      display: flex;
      font-size: 12px;
      margin-top: var(--sm);
      text-align: left;

      &-intercom-btn {
        text-decoration: none;
      }

      &-dropdown {
        display: inline-block;
      }

      &-profile {
        background: none;
        border: 0;
        color: var(--blue);
        display: inline-block;
        padding: 0;
      }

      &-videobutton {
        align-items: center;
        background: none;
        border: 0;
        color: var(--blue);
        display: inline-flex;
        font-weight: var(--font-weight-bold);
        gap: 3px;
        padding: 0;
        position: relative;
        top: 1px;

        &-icon {
          align-items: center;
          background: var(--blue);
          border-radius: var(--border-radius);
          color: white;
          display: inline-flex;
          height: 12px;
          justify-content: center;
          padding: 2px;
          width: 15px;
        }
      }
    }
  }

  &-language-picker {
    margin-bottom: var(--xs);

    :global(.rm-LanguageButton) {
      background: var(--LanguageButton-bg);
      border: var(--border-width) solid var(--LanguageButton-button-border);
      color: var(--LanguageButton-button-color);
      font-size: 13px;
      font-weight: var(--font-weight);
      gap: var(--xs);
      min-width: var(--LanguageButton-width);
      padding: 0 10px;
      transition: box-shadow var(--transition-fast) var(--transition-timing);
      white-space: nowrap;

      &:active,
      &:focus {
        border-color: var(--LanguageButton-button-border-active);
      }

      &:focus {
        box-shadow: 0 0 0 3px var(--LanguageButton-button-border-focus);
      }
    }

    :global(.rm-LanguageButtonBtnGroup_active),
    :global(.rm-LanguageButtonBtnGroup_active):hover,
    :global(.rm-LanguageButtonBtnGroup_active):active {
      color: var(--blue);
    }
  }

  &-section {
    border-bottom: 1px solid var(--color-border-default);
    padding: var(--md) 0;

    &-title {
      font-size: 14px;
      font-weight: var(--font-weight-bold);
    }

    &-description {
      font-size: 14px;
      padding-bottom: var(--sm);
      padding-top: var(--xs);
    }
  }

  &-variables {
    margin-bottom: var(--md);

    &-text {
      color: var(--gray30);
      display: inline-flex;
      flex-wrap: wrap;
      font-size: 12px;
      gap: 2.5px;
      margin: 5px 0 10px;
    }

    &-expanded {
      padding-left: var(--md);
    }

    &-section-tooltip {
      $block: &;

      align-items: center;
      cursor: pointer;
      display: inline-flex;
      gap: 3px;

      &-icon {
        opacity: 0.5;
      }

      &:hover,
      &:active,
      &:focus {
        #{$block}-icon {
          opacity: 1;
        }
      }
    }

    &-summary {
      $block: &;

      font-weight: var(--font-weight);

      &:hover {
        color: var(--color-text-minimum-hover);
      }

      &-icon {
        transform: rotate(0);
        transition: 0.1s transform;
      }

      &_open {
        #{$block}-icon {
          transform: rotate(90deg);
        }
      }
    }
  }

  &-infobert-callout {
    margin-bottom: var(--sm);

    // select second div
    div:nth-child(2) {
      justify-content: center;
    }
  }

  &-ngrok-instructions {
    margin-bottom: var(--sm);
  }

  &-setup-card-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 500px;
    grid-template-rows: 75px auto auto auto;

    &-item {
      padding: var(--sm);
    }

    @media (max-width: $dash-container-lg) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }

  &-variables-summary {
    $block: &;

    align-items: center;
    background: transparent;
    border: 0;
    color: var(--color-text-minimum);
    display: flex;
    font-size: 11px;
    font-weight: var(--font-weight);
    gap: 2px;
    outline: none;
    padding: 0;
    text-transform: uppercase;

    &:hover {
      color: var(--color-text-minimum-hover);
    }

    &-icon {
      transform: rotate(0);
      transition: 0.1s transform;
    }

    &_open {
      #{$block}-icon {
        transform: rotate(90deg);
      }
    }
  }

  &-variables-ctn {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--md);
    justify-content: center;
    margin: 0 auto;
    margin-top: var(--lg);
    max-width: 900px;
    padding: 0 var(--md);
  }

  &-text {
    color: var(--gray30);
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 12px;
    gap: 2.5px;
    margin: 5px 0 10px;
  }



  &-url-input {
    width: 100%;
  }

  &-apiKey button {
    backdrop-filter: blur(var(--sm));
    background-color: rgba(var(--gray15-rgb), 0.9);
    border-color: var(--gray20);
    bottom: var(--sm);
    font-size: 12px;
    position: absolute;
    right: var(--sm);
    width: auto;

    &:hover {
      background-color: inherit;
      border-color: var(--gray30);
    }
  }
}

.LogReceivedCard {
  background-color: var(--gray10);
  margin-top: -20px; // merge with the "listening for your call" above w/o refactoring this code
  padding: 0;

  &-header-img {
    border-radius: var(--border-radius);
    height: 150px;
    margin: var(--sm) var(--sm) 0;
    width: auto;
  }

  &-body {
    max-width: 100%;
    overflow: auto;
    padding: var(--sm);

    &-description {
      color: white;
      font-family: var(--font-family-mono);
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      font-weight: var(--font-weight);
      margin-bottom: 0;
      margin-top: $xs;

      &-link {
        align-items: center;
        display: inline-flex;
        gap: var(--xs);

        &-icon {
          opacity: 0.5;
        }
      }
    }

    &-log {
      background: none;
      color: white;
      max-width: 100%;
      overflow-x: auto;
      padding: 0;
      white-space: nowrap;

      code {
        margin-right: $xs;
      }
    }
  }
}

.LogReceivedModal {
  &-header {
    padding: 0 !important;

    &-img {
      width: 100%;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    text-align: center;

    &-description {
      color: var(--color-text-muted);
      margin-top: $xs;
    }

    &-log {
      overflow-x: auto;
      padding: $xs;
      white-space: nowrap;

      code {
        margin-right: $xs;
      }
    }
  }
}

.InteractiveHero {
  &-hero {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--md);
    margin: 0 auto;
    max-width: 900px;
    width: 100%;

    &-figure {
      position: relative;
      width: 100%;
    }

    &-title {
      color: var(--blue0);
      font-family: Marfa, sans-serif;
      font-size: 40px;
      font-weight: var(--font-weight-bold);
      text-align: center;
    }

    &-highlight {
      color: var(--blue);
    }
  }
}

.RefUI,
.AuthUI {
  --devdash-bg: 255, 255, 255;
  --devdash-bg-opacity: 0.5;
  --devdash-border: var(--color-border-default);

  $block: &;

  backdrop-filter: blur(var(--md));
  background: rgba(var(--devdash-bg), var(--devdash-bg-opacity));
  border: 1px solid var(--devdash-border);
  border-radius: var(--border-radius-lg);
  box-shadow:
    inset 0 1px 1px rgba(white, 0.5),
    0 2px 5px rgba(black, 0.025),
    0 5px 15px rgba(black, 0.025);
  color: var(--color-text-default);
  display: flex;
  font-family: var(--font-family);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 550px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 800px;

  @media (max-width: 1200px) {
    width: 100%;
  }

  &-sidebar {
    border-right: 1px solid var(--color-border-default);
    font-size: 12px;
    height: 100%;
    max-width: 200px;
    overflow: hidden;
    padding: var(--sm);

    @media (max-width: 1200px) {
      display: none;
    }

    &-jumpTo {
      align-items: center;
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      color: var(--color-text-minimum);
      display: flex;
      font-size: 0.65em;
      font-weight: var(--font-weight-bold);
      height: 25px;
      justify-content: space-between;
      margin-bottom: var(--sm);
      padding: 0 5px;

      &-key {
        border: 1px solid var(--color-border-default);
        border-radius: var(--border-radius);
        padding: 2px 3px;
      }
    }

    &-link {
      align-items: center;
      border-radius: var(--border-radius);
      color: var(--color-text-muted);
      display: flex;
      font-size: 1em;
      font-weight: var(--font-weight-normal);
      padding: 0.3em 0.3em 0.3em 1.2em;

      &_category {
        font-size: 0.9em;
        font-weight: var(--font-weight-bold);
        padding-top: 1.5em;
      }

      &_active {
        background: rgba(var(--blue-rgb), 0.09);
        color: var(--blue);
      }

      + #{$block}-sidebar-link {
        margin-top: 1px;
      }

      &-icon,
      &-chevron {
        font-size: 1.1em;
        margin-right: 0.5em;
      }

      &-icon {
        opacity: 0.5;
      }

      &-chevron {
        margin-left: -1.5em;
        opacity: 0.5;
      }

      &-method {
        align-items: center;
        border-radius: 1.75em;
        box-shadow: var(--box-shadow-pill);
        color: white;
        display: inline-flex;
        font-size: 0.6em;
        font-weight: var(--font-weight-bold);
        height: 1.75em;
        justify-content: center;
        margin-left: auto;
        padding: 2px 5px;
        text-transform: uppercase;
        white-space: nowrap;
        width: 5em;

        &_get {
          background: var(--green40);
        }

        &_delete {
          background: var(--red40);
        }

        &_post {
          background: var(--blue);
        }

        &_put {
          background: var(--purple);
        }
      }
    }
  }

  &-content {
    flex: 1;
    padding: 20px 30px;

    &-heading {
      font-size: 1.5em;
      font-weight: var(--font-weight-bold);
      line-height: 1;
      padding-bottom: 0.5em;
      text-align: left;

      &_h1 {
        border-bottom: 1px solid var(--color-border-default);
      }

      &_h2 {
        font-size: 1.25em;
        margin-top: 1.5em;
      }

      &-method {
        align-items: center;
        background: var(--green40);
        border-radius: 1.75em;
        box-shadow: var(--box-shadow-pill);
        color: white;
        display: inline-flex;
        font-size: 0.35em;
        font-weight: var(--font-weight-bold);
        height: 1.75em;
        justify-content: center;
        margin-left: auto;
        padding: 2px 5px;
        text-transform: uppercase;
        white-space: nowrap;
        width: 5em;
      }

      &-server {
        font-size: 0.6em;
        font-weight: var(--font-weight-normal);
      }
    }

    &-paragraph {
      color: var(--color-text-muted);
      line-height: 1.4;
      text-align: left;
    }

    &-section {
      font-size: 13px;
      margin-top: 2em;

      &-heading {
        color: var(--color-text-minimum);
        font-size: 0.8em;
        font-weight: var(--font-weight-bold);
        margin-bottom: 0.75em;
        text-align: left;
      }
    }

    &-table {
      $steps: 5;

      background: rgba(white, 0.3);
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      box-shadow: inset 0 1px 1px rgba(white, 0.3);
      font-size: 13px;
      white-space: nowrap;

      &-status {
        padding: 0;
      }

      &-key {
        background: transparent;
        border-radius: 1em;
        color: var(--color-text-muted);
        display: inline-block;
        font-family: var(--font-family-mono);
        font-size: 10px;
        line-height: 1;
        padding: 2px var(--xs);

        &_active {
          animation: key-in 0.15s forwards;
          background: var(--blue);
          color: var(--white);
          font-weight: var(--font-weight-bold);

          @keyframes key-in {
            0% {
              opacity: 0.5;
              transform: scale(0.9);
            }

            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }

      &-check {
        align-items: center;
        color: var(--blue);
        display: inline-flex;
        font-size: 1em;
        font-weight: 800;
        height: 1.5em;
        justify-content: center;
        opacity: 0;
        width: 1.5em;

        &:global(.active) {
          animation: check 0.15s ease-in-out forwards;
        }

        @keyframes check {
          0% {
            opacity: 0;
            transform: scale(0.75);
          }

          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      &-replay {
        background: transparent;
        border-radius: 1em;
        color: var(--color-text-muted);
        font-family: var(--font-family);
        font-size: 10px;
        gap: 0.25em;
        line-height: 1;
        padding: 3px var(--xs);

        &:global(.active) {
          animation: replay-in 0.15s forwards;
          background: var(--blue);
          color: var(--white);
          font-weight: var(--font-weight-bold);

          @keyframes replay-in {
            0% {
              opacity: 0.5;
              transform: scale(0.9);
            }

            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }

      table {
        width: 100%;
      }

      tbody tr {
        border-top: 1px solid var(--color-border-default);

        &:global(:not(.active)) {
          cursor: pointer;
        }

        &:hover {
          background: rgba(black, 0.025);
        }

        td {
          border-top: 1px solid var(--color-border-default);
        }
      }

      th,
      td {
        height: 2.5em;
        line-height: 2.5em;
        padding: 0 1em;
        text-align: left;

        &:first-child {
          width: 150px;
        }

        &:last-child {
          width: 25px;
        }
      }

      th {
        color: var(--color-text-minimum);
        font-size: 0.8em;
        font-weight: var(--font-weight-bold);
        position: relative;
      }

      td {
        color: var(--color-text-muted);
        font-size: 0.9em;

        i {
          color: var(--color-text-minimum);
          margin-left: 0.5em;
        }
      }
    }
  }

  &-form {
    background: var(--gray100);
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    font-size: 0.85em;
    padding: 0.75em;

    &-param {
      color: var(--color-text-default);
      font-weight: var(--font-weight-bold);
    }

    &-type {
      color: var(--color-text-minimum);
    }

    &-required {
      color: var(--red);
    }

    &-input {
      background: white;
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      color: var(--gray70);
      padding: 0.25em 0.5em;
      white-space: nowrap;
      width: 200px;
    }
  }
}

.TryItUI {
  --devdash-bg: var(--gray100-rgb);
  --devdash-bg-opacity: 0.5;
  --devdash-border: var(--color-border-default);

  $block: &;

  backdrop-filter: blur(var(--sm));
  background: rgba(var(--devdash-bg), var(--devdash-bg-opacity));
  border: 1px solid var(--devdash-border);
  border-radius: var(--border-radius-lg);
  bottom: -15px;
  box-shadow:
    inset 0 1px 1px rgba(white, 0.5),
    0 2px 5px rgba(black, 0.025),
    0 5px 15px rgba(black, 0.025);
  color: var(--color-text-default);
  display: flex;
  flex-direction: column;
  font-family: var(--font-body);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  gap: var(--sm);
  margin: 0 auto;
  padding: var(--md);
  position: absolute;
  right: -20px;
  width: 400px;

  &-section {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 1em;

    &-heading {
      color: var(--color-text-muted);
      font-size: 0.9em;
      font-weight: var(--font-weight-bold);
      text-align: left;
    }
  }

  &-languages {
    display: flex;

    &-language {
      align-items: center;
      border: 1px solid transparent;
      border-radius: var(--border-radius);
      display: flex;
      flex-direction: column;
      font-size: 0.9em;
      gap: 0.75em;
      padding: 0.75em;
      width: 6em;

      &_active {
        border-color: var(--color-border-default);
      }

      &-icon {
        font-size: 1.5em;
      }
    }
  }

  &-auth {
    align-items: center;
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    display: flex;
    font-size: 12px;
    height: 3em;

    &-type {
      align-items: center;
      border-right: 1px solid var(--color-border-default);
      display: flex;
      font-weight: var(--font-weight-bold);
      height: 100%;
      padding-left: 1em;
      padding-right: 1em;
    }

    &-badge {
      animation: key-in 0.3s forwards;
      background: var(--blue);
      border-radius: 1em;
      color: var(--white);
      display: inline-block;
      font-family: var(--font-family-mono);
      font-size: 10px;
      font-weight: var(--font-weight-bold);
      margin-left: var(--xs);
      padding: 2px var(--xs);
      transform-origin: 50%;

      @keyframes key-in {
        0% {
          transform: scale(0.9);
        }

        50% {
          transform: scale(1.1);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }

  &-code {
    background: var(--gray20);
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    overflow: hidden;
    padding-bottom: 0.75em;
    padding-top: 0.75em;

    .TryItUI-section-heading {
      color: rgba(white, 0.75);
      padding-left: 0.75em;
      padding-right: 0.75em;
    }

    &_request {
      background: var(--gray100);
      color: var(--color-text-muted);

      .TryItUI-section-heading {
        color: var(--color-text-minimum);
      }
    }

    &-footer {
      align-items: center;
      border-top: 1px solid rgba(white, 0.1);
      display: flex;
      justify-content: flex-end;
      padding-left: 0.75em;
      padding-right: 0.75em;
      padding-top: 0.75em;

      &-button {
        background: var(--blue);
        border-radius: var(--border-radius);
        font-weight: var(--font-weight-bold);
        padding: 0.5em;
      }
    }
  }

  &-codeMirror {
    color: currentcolor;
    display: flex;
    font-family: var(--font-family-mono);
    font-size: 11px;
    padding: 0.75em 0.5em;
    text-align: left;
    white-space: nowrap;

    &-linenumbers {
      color: #bebcd7;
      display: flex;
      flex-direction: column;
      padding-left: 1em;
      padding-right: 1em;
    }

    :global {
      .cm-attribute {
        color: #d7b2f0;
      }

      .cm-builtin {
        color: #ffcb6b;
      }

      .cm-string {
        color: #c3e88d;
      }

      .cm-badge {
        background: var(--blue);
        border-radius: 1em;
        color: var(--white);
        font-size: 10px;
        font-weight: var(--font-weight-bold);
        padding: 1px var(--xs);
      }
    }
  }
}
