@import '@core/styles/vars';

.SidebarNavSection {
  padding-bottom: var(--sm);

  &-label {
    color: var(--color-text-minimum);
    font-size: 11px;
    font-weight: var(--font-weight);
    letter-spacing: 0.025em;
    padding: var(--xs);
    text-transform: uppercase;
    user-select: none;
  }

  &-content {
    margin: 0;
    padding: 0;
  }
}
