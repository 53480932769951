.PreviewFigure {
  background-color: var(--gray10);
  border-radius: var(--border-radius-lg);
  box-sizing: border-box;
  margin: 25px 0 0;
  max-width: 220px;
  overflow: hidden;
  padding: var(--sm) var(--sm) 0;

  &-caption {
    display: flex;
    margin-bottom: 15px;
  }

  &-image {
    margin-bottom: calc(-1 * var(--md));
    margin-left: var(--sm);
    margin-right: var(--sm);
  }

  @container page-content (max-width: 700px) {
    margin-top: 0;
  }
}
