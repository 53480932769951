.Cover {
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  z-index: var(--z-modal);
}
