@import '@core/styles/vars';

.BottomBar {
  align-items: center !important;
  background: var(--SuperHub-background);
  border-top: 1px solid var(--gray10);
  bottom: 0;
  box-sizing: border-box;
  height: var(--MyDevelopers-bottom-bar-height);
  left: 0;
  padding: var(--xs);
  position: fixed;
  width: 100%;
  z-index: var(--z-modal);
  
  &-container {
    align-items: center;
    display: flex;
    gap: var(--md);
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: calc(var(--container-lg) - var(--SuperHub-margin) * 2 - var(--MyDevelopers-padding) * 2);
  }

  &-controls {
    display: flex;
    justify-content: flex-end;
    min-width: 40px;
    position: relative;

    &::before {
      background-color: var(--gray30);
      border-radius: var(--border-radius);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 1px;
    }
  }
}
