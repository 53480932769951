.SetupWebhookStep {
  &-container {
    box-sizing: border-box;
    margin: auto;
    max-width: 575px;
    padding: var(--lg) 0;
  }

  &-header {
    margin-bottom: var(--md);
  }

  &-title { 
    align-items: center;
    display: flex;
    gap: var(--sm);
    margin: 0;
  }

  &-description { 
    color: var(--semantic-text-muted-dark, #C6CBD3);
    font-size: 13px;
    margin: var(--md) 0 0;

    a {
      color: inherit;
      text-decoration-line: underline;
    }
  }

  &-separator {
    background-color: var(--color-border-default);
    height: 1px;
    width: 100%;
  }

  &-finish {
    width: 100%;
  }

  &-finish-button {
    align-self: end;
    background-color: var(--green10);

    &:hover {
      background-color: var(--green10);
    }
  }

  &-instructions {
    width: 100%;
  }
} 
