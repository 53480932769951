.PurpleBox {
  background: var(--purple30);
  box-shadow: 0 0 0 1px inset rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  color: var(--white);

  :global(.Button_contrast:not(.Button_contrast_text)) {
    color: var(--purple20);
  }
}
