.PersonalizedDocs {
  &-container{
    margin-top: 24px;
  }

  &-separator {
    background-color: var(--color-border-default);
    height: 1px;
    width: 100%;
  }

  &-footer {
    align-self: flex-end;
  }

  &-enterprise {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;  
  }
}
