.ProjectNavigationButton {
  font-size: 13px;
  height: 24px;
  width: inherit;

  // Mute the chevron icon color for dropdown buttons
  &[aria-expanded] > :global(.IconWrapper:last-child) {
    opacity: 0.5;
    transition: opacity var(--transition-fast);
  }

  // Correct colors for icon-only dropdown buttons
  &[aria-expanded] > :global(.IconWrapper:first-child) {
    opacity: 1;
  }

  // Highlight the chevron icon color for dropdown buttons
  &:not(:disabled):hover:global(.Dropdown-toggle > .IconWrapper:last-child),
  &[aria-expanded="true"]:global(.Dropdown-toggle > .IconWrapper:last-child) {
    opacity: 0.75;
  }
  
  // Text "label" color
  &,
  &:global(.Button) {
    color: var(--color-text-default);
    transition: color var(--transition-fast);
  }

  &:global(.Button):not(:disabled):hover,
  &:global(.Button)[aria-expanded="true"] {
    color: var(--color-text-muted);
  }

  &-tooltip :global(.tippy-content) {
    font-size: 12px;
    padding: 0 2px;
  }
}
