@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.VariablesTable {
  position: relative;
  width: 100%;

  &-table {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    margin-bottom: var(--sm);
    width: 100%;

    &:global(.Table) {
      tr {
        transition: background-color var(--transition-fast);
      }

      tbody td {
        font-size: 13px;
        height: 25px;
        width: 100%;
      }

      thead th:first-child,
      tbody td:first-child {
        padding-left: var(--sm);
      }
    }

    :global(.Input) {
      width: 100%;
    }

    &_editable:global(.Table) {
      thead th:last-child {
        width: 44px;
      }

      tbody td,
      tbody td:first-child {
        padding: var(--xs);
      }

      tbody td:not(:first-child):not(:last-child) {
        padding-right: 0;
      }
    }

    &_empty:global(.Table) {
      tbody td {
        color: var(--gray40);
        text-align: center;
      }
    }

    &:not(.VariablesTable-table_editable):not(.VariablesTable-table_empty):global(.Table) {
      --row-background: var(--gray100);

      @include dark-mode {
        --row-background: var(--gray20);
      }

      tbody tr:hover {
        background-color: var(--row-background);
      }
    }
  }

  &-preview {
    margin-top: var(--sm);
  }

  &-preview-summary {
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--color-text-minimum);
    cursor: pointer;
    display: flex;
    font-size: 11px;
    font-weight: var(--font-weight);
    gap: 2px;
    outline: none;
    padding: 0;
    text-transform: uppercase;

    &:hover {
      color: var(--color-text-minimum-hover);
    }

    &-icon {
      transform: rotate(0);
      transition: 0.1s transform;
    }

    &_open .VariablesTable-preview-summary-icon {
      transform: rotate(90deg);
    }
  }
}
