.AwsWebhookIntegration {  
  &-toggle-content {
    display: flex;
    flex-direction: column;
    gap: var(--xs);
    padding: var(--sm) 0;
  }

  &-title {
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: 1.3;
    margin-top: 0;
  }

  &-subtitle {
    margin: 0;
  }
}
