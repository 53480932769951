.LockedField {
  align-items: flex-start;
  background-color: var(--gray10);
  border-radius: var(--border-radius-lg);
  display: grid;
  font-size: 12px;
  gap: var(--sm);
  grid-template-columns: auto 1fr;
  line-height: 1.4;
  padding: var(--sm);

  &-icon {
    transform: translateY(3px);
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  :global(p) {
    margin: 0;
  }

  :global(a) {
    color: var(--blue);
    text-decoration: none;

    &:hover {
      color: var(--blue);
      text-decoration: underline;
    }
  }
}
