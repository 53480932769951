.SidebarNavItem {
  --SidebarNavItem-height: 80px;

  $root: &;

  overflow: hidden;
  position: relative;

  &:not(#{$root}_loading)::after {
    border-bottom: 1px solid var(--color-border-default);
    content: '';
    display: block;
    height: calc((100% - var(--SidebarNavItem-height)) / 2);
    position: absolute;
    top: var(--SidebarNavItem-height);
    width: 100%;
  }

  &_loading {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &-link {
    all: unset;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: var(--SidebarNavItem-height);
    justify-content: space-between;
    padding: var(--sm);
    transition: background var(--transition-timing);

    &_active,
    &:hover {
      background-color: rgba(var(--blue-rgb), 0.1);
    }
  }

  &-title {
    color: var(--color-text-default);
    display: inline;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &_placeholder {
      color: var(--color-text-minimum);
      font-style: italic;
    }
  }

  &-description,
  &-meta {
    font-size: 12px;
  }

  &-description {
    color: var(--color-text-muted);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-meta {
    color: var(--color-text-minimum);
  }
}
