@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.MetricsDateRange {
  &-btn-group {
    align-items: center;
    display: flex;
    gap: var(--sm);
  }

  &_dark-on-black {
    @include dark-mode {
      background: #{rgba(white, 0.10)};
    }
  }

  &-disabled-btn,
  &-disabled-btn:hover,
  &-disabled-btn:not(:disabled):hover {
    color: rgba(var(--gray20-rgb), 0.5);
  }

  &-active-disabled-btn,
  &-active-disabled-btn:hover,
  &-active-disabled-btn:not(:disabled):hover {
    color: rgba(var(--blue-rgb), 0.5);
  }

  &-menu {
    width: 150px;

    &-arrow {
      opacity: 0.5;
    }
  }

  &-link {
    &_paywall {
      .icon {
        display: inline-block;
        transition:
          opacity margin var(--transition-fast) var(--transition-timing),
          margin var(--transition-fast) var(--transition-timing);
      }

      &:hover,
      &:focus {
        color: inherit;

        .MetricsDateRange-menu-arrow {
          margin-left: 2px;
          opacity: 1;
        }
      }
    }
  }
}

.MetricsDateRangeCustomTooltip {
  @include dark-mode {
    background-color: var(--gray15);
  }

  background-color: var(--gray100);
  border-radius: 5px;
  padding: 0;
  width: 200px;

  &-tabs-list {
    justify-content: center;
  }

  &-content {
    padding: var(--sm);

    :global(.FormGroup + .FormGroup) {
      margin-top: var(--sm);
    }

    input {
      width: 100%;
    }

    input[type='number'] {
      appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  &-buttons {
    border-top: 1px solid var(--color-border-default);
    padding: var(--sm);
  }
}
