.SetupAWSPersonalizationForm {
  &-cross-acount-list {
    margin: 0;
  }
  
  &-input {
    width: 100%;
  }

  &-label {
    line-height: 1.5;

    &-title {
      font-weight: var(--font-weight-bold);
    }
  
    &-subtitle {
      margin-top: 0;
    }
  }

  &-submit {
    margin-top: var(--sm);
  }
}
