@import '@core/styles/vars';

.MetricsView {
  background: var(--gray100);
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  overflow: auto;

  &_with-bottom-bar {
    min-height: auto;
    overflow: inherit;
  }
}
