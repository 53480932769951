.SetupApiLogs {
  &-container {
    height: 100%;
    position: relative;
    width: 100%;
  }

  &-view-demo {
    position: absolute;
    bottom: var(--md);
    left: var(--md);
    z-index: 100;
    background-color: var(--green10);

    &:hover {
      background-color: var(--green20);
    }
  }
}

