.FormRow {
  --FormRow-columns: 2;
  --FormRow-x-gap: 100px;
  --FormRow-y-gap: 30px;
  --FormRow-min-col-width: calc(860px / var(--FormRow-columns) - var(--FormRow-x-gap));
  --FormRow-max-col-width: calc(100% - var(--FormRow-x-gap));

  $block: &;

  align-items: flex-start;
  display: grid;
  gap: var(--FormRow-y-gap) var(--FormRow-x-gap);
  grid-auto-flow: row;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(min(var(--FormRow-min-col-width), var(--FormRow-max-col-width))), 1fr)
  );
  max-width: 800px;
  width: 100%;

  &_force-column-flow {
    grid-auto-flow: column;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(min(var(--FormRow-min-col-width), var(--FormRow-max-col-width))), 1fr)
    );
    grid-template-rows: repeat(var(--FormRow-child-count), auto);
  }

  + #{$block} {
    margin-top: var(--FormRow-y-gap);
  }

  &_condensed {
    --FormRow-x-gap: var(--md);
    --FormRow-y-gap: var(--md);
    --FormRow-min-col-width: 120px;

    + #{$block}_condensed {
      margin-top: var(--md);
    }
  }

  &_full-width {
    max-width: 100%;
  }

  // We will use .FormGroup alot inside the FormRow, so .FormGroup specific spacing styles
  // allow us to use them as direct children without wrapping them in containers
  :global(.FormGroup) + :global(.FormGroup) {
    margin-top: var(--FormGroup-gap);
  }

  > *:global(.FormGroup) + :global(.FormGroup) {
    margin-top: 0;
  }
}
