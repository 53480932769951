.Glossary {
  $block: &;

  &-header {
    align-items: end;
    display: grid;
    gap: var(--lg);
    grid-template-columns: 1fr auto;
    margin-bottom: var(--md);

    &-cta {
      margin-bottom: var(--xs);
    }
  }

  &-heading {
    color: var(--color-text-default);
    font-size: 16px;
    font-weight: var(--font-weight);
    margin: 0;
  }

  &-sub-heading {
    color: var(--color-text-muted);
    font-size: 14px;
    line-height: 1.6;
    margin: var(--xs) 0 0 0;
    max-width: 56ch;
  }

  &-example {
    border-bottom: 1px dotted var(--color-text-minimum);
    cursor: pointer;
  }

  &-list {
    background-color: var(--gray0);
    border-radius: var(--border-radius);
    font-size: 14px;
    line-height: 1.4;
    list-style: none;
    margin: var(--sm) 0 0 0;
    padding: 0;

    &-item {
      border: 1px solid var(--color-border-muted);
      border-bottom: 0;
      position: relative;
      transition: background-color var(--transition-fast), opacity var(--transition-fast);

      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);  
      }

      &:last-child {
        border-bottom: 1px solid var(--color-border-muted);
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }

      &:hover {
        background-color: rgba(var(--white-rgb), 0.05);
        border-bottom: 1px solid var(--color-border-muted);
        
        + #{$block}-list-item {
          border-top: 0;
        }

        #{$block}-term-delete {
          opacity: 1;
        }
      }

      &_editing {
        border-bottom: 1px solid var(--color-border-muted);

        &,
        &:hover {
          background-color: rgba(var(--white-rgb), 0.10);
        }

        + #{$block}-list-item {
          border-top: 0;
        }
      }

      &_inactive {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &-term {
    all: unset;
    background: none;
    border: 0;
    box-sizing: border-box;
    display: grid;
    font-size: 14px;
    grid-template-columns: 120px 4fr auto;
    overflow: hidden;
    padding: 0;
    text-align: left;
    width: 100%;

    &:focus,
    &:hover {
      cursor: pointer;
    }

    &-name,
    &-definition {
      line-height: 1.4;
      margin: 0;
      padding: 12px;
    }

    &-name {
      border-right: 1px solid var(--color-border-default);
      box-sizing: border-box;
      height: 100%;
      word-wrap: break-word;
    }

    &-usage {
      color: var(--color-text-muted);
      font-size: 12px;
    }

    &-preview {
      background: rgba(var(--white-rgb), 0.15);
      border-radius: var(--border-radius);
      font-family: var(--font-family-mono);
      font-size: 11px;
      padding: 1px 4px;
    }
  }

  &-input {
    #{$block}-term {
      &:focus,
      &:hover {
        cursor: default;
      }
    }

    &-field {
      --padding: 4px;

      // account for a negative margin on the input to align the input text with the non-editing state
      --offset: calc(var(--padding) + 1px); 

      all: unset;
      background: none;
      border: 1px solid transparent;
      border-radius: var(--border-radius);
      box-sizing: border-box;
      cursor: text;
      font-size: 14px;
      line-height: 1.4;
      margin: calc(var(--offset) * -1);
      padding: var(--padding);
      width: calc(100% + (var(--offset) * 2));

      &:hover {
        background: var(--color-input-background);
        border: 1px solid var(--color-input-border-hover);
      }

      &:active,
      &:focus {
        background: var(--color-input-background);
        border: 1px solid var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
        outline: none;
      }
    }

    &-footer {
      border-top: 1px solid var(--color-border-default);
      padding: var(--sm);
    }
  }
}
