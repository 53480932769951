.PlanAccessBadge {
  align-items: center;
  border-radius: var(--border-radius);
  display: flex;
  font-size: 10px;
  font-weight: var(--font-weight);
  gap: 2px;
  height: 15px;
  justify-content: center;
  line-height: 1;
  padding: 0 4px;
  width: fit-content;

  // Need a specificity bump when used within a MenuItem to protect against inherited style overrides on the icon 
  :global(.IconWrapper:first-child) {
    font-size: 10px;
    margin: 0;
    opacity: 1;
  }

  &_iconOnly {
    aspect-ratio: 1;
    padding: 0;
  }

  &-banner {
    width: 260px;
  }
}
