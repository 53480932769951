.PersonalizedDocsLayout {
  --MockTryIt-margin: 64px;

  align-items: normal;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(var(--green40-rgb), 0.2) 100%), #000;
  display: grid;
  grid-template-columns: 1fr 480px;
  height: 100%;
  min-height: 0;
  overflow: auto;
  width: 100%;

  &-content {
    padding: var(--md);
  }

  &-mock-try-it {
    background: rgba(var(--white-rgb), 0.95);
    border-radius: var(--border-radius);
    box-shadow: inset 0 2px 4px rgba(var(--gray0-rgb), 0.8);
    box-sizing: border-box;
    color: var(--color-bg-page);
    height: fit-content;
    margin: var(--MockTryIt-margin) 0 0;
    padding: var(--md);
    position: sticky;    
    top: var(--MockTryIt-margin);
  }
}
