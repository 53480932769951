.IntegrationFieldset {
  --IntegrationFieldset-icon: 25px;
  --IntegrationFieldset-y-padding: 30px;

  $block: &;

  align-items: flex-start;
  border: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  min-width: 0;
  padding: 0;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: var(--IntegrationFieldset-y-padding);
  }

  + #{$block} {
    border-top: 1px solid var(--color-border-default);
    padding-top: var(--IntegrationFieldset-y-padding);
  }

  &_baseline #{$block}-fields {
    margin-top: 7px;
  }

  &-legend {
    align-items: center;
    color: var(--color-text-default);
    display: flex;
    flex: 0 0 auto;

    // Setting float to not 'none' will remove browser default "rendered legend" layout
    // https://html.spec.whatwg.org/multipage/rendering.html#rendered-legend
    // and legend will appear normally in the page flow.
    float: left;
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    gap: var(--sm);
    height: var(--button-sm);
    margin-bottom: var(--sm);
    min-width: 150px;
    width: 33%;

    &-icon {
      font-size: var(--IntegrationFieldset-icon);
    }
  }

  &-fields {
    flex: 1;
  }

  @container page-content (max-width: 530px) {
    --IntegrationFieldset-icon: 20px;
    --IntegrationFieldset-y-padding: 20px;

    align-items: stretch;
    flex-direction: column;
  }
}
