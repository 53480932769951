@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.MyDevelopers {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
  width: 100%;

  &-superhub {
    box-sizing: border-box;
    height: calc(100% - var(--MyDevelopers-bottom-bar-height));
    margin-top: var(--sm);
  }

  &-loading {
    height: 100vh;
    width: 100%;
  }

  &-loading-wrapper {
    height: 100%;
  }

  &-header {
    background: var(--MyDevelopers-bg) !important;
    position: sticky;
    top: 0;
  }

  &-circleEffect {
    background: radial-gradient(50% 50% at 50% 50%, #12CA93 0%, rgba(18, 202, 147, 0.00) 100%);
    border-radius: 50%;
    filter: blur(100px);
    height: 1236.495px;
    left: 50%;
    opacity: 0.3;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(56.508deg);
    width: 331px;
  }

  &-layout {
    align-items: flex-start;
    display: grid;
    gap: 2rem;
    grid-template-columns: 250px 1fr;
    height: 100%;
    position: relative;
  }
}

