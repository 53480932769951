@import '~@core/styles/vars.compat';

.Instructions {
  &-installation {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &-code {
    background: rgba(var(--gray10-rgb), 0.9);
    border-radius: var(--border-radius);
    position: relative;

    :global(.CodeSnippet) {
      background: inherit;
      border: unset;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      border-radius: var(--border-radius);

      :global(.CodeMirror) {
        overflow: auto;
        padding: var(--sm);
        white-space: nowrap;
      }
    }

    @at-root button#{&}-copy {
      border: 0;
      color: var(--gray60);
      position: absolute;
      right: $xs;
      top: 4px;
      z-index: 1;

      &:not(:disabled):hover,
      &:not(:disabled):focus-visible {
        color: var(--gray70);
      }
    }

    /* Ensure there's room for the API Key selector dropdown */
    &-withApiKey :global(.CodeMirror) {
      padding-bottom: 2.25em !important;
    }
  }

  &-setup-card-grid {
    display: grid;
    gap: var(--sm);
    grid-template-columns: auto;

    &-item {
      overflow: auto;
      padding: var(--xs) 0;
    }

    @media (max-width: $dash-container-lg) {
      grid-template-columns: 1fr;
    }
  }

  &-listener {
    border-radius: var(--border-radius);
    color: white;
    display: flex;
    font-family: var(--font-family-mono);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    font-weight: var(--font-weight);
    margin: var(--sm) 0;
    padding: var(--sm);

    div[role='progressbar'] {
      --spinner-color: var(--gray60);
    }

    span {
      margin-left: var(--sm);
    }
  }
}
