@import '@core/styles/vars';

.MetricsTableCell {
  &-page-url {
    $block: &;
    align-items: center;
    color: inherit;
    display: inline-flex;
    gap: var(--xs);
    max-width: 100%;
    overflow: hidden;
    &-text {
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      color: var(--gray60);
      flex: 0 0 auto;
      transition: transform var(--transition-slow) var(--transition-timing);
    }

    &:hover,
    &:active,
    &:focus {
      color: inherit;

      #{$block}-icon {
        transform: translate3d(1px, -1px, 0);
      }
    }
  }

  &-user {
    width: 100%;

    &-label {
      flex: 1;
      min-width: 0;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    &_secondary {
      color: var(--color-text-minimum);
      font-size: 11px;
    }
  }
}
