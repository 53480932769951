@import '~@core/styles/vars';

.RecentRequestTable {
  tbody:hover {
    tr:hover {
    background: var(--Table-hover-bg);
    }
  }

  // if we are in the superhub collapsed view, decrease the height of the
  // empty state so that it's more likely to be in view
  &_superhub-collapsed-empty {
    max-height: fit-content;
  }

  &-link{
    color: var(--color-text-muted);
    text-decoration: underline !important;
  }

  &-link:hover{
    color: var(--color-text-default);
    cursor: pointer;
  }

  &-td {
    &-empty {
      color: var(--gray80);
    }

    &-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      word-wrap: break-word;
    }

    &-method {
      align-items: center;
      display: flex;
      gap: var(--xs);
    }

    :global(.Badge) {
      align-items: center;
      background-color: var(--gray90);
      border-radius: 16px;
      box-shadow:
        0 1px 2px rgba(black, 0.05),
        inset 0 -1px 2px rgba(black, 0.2),
        inset 0 1px 1px rgba(white, 0.2);
      box-sizing: border-box;
      display: flex;
      flex: 0 0 auto;
      height: 16px;
      justify-content: center;
      min-width: 16px;
      padding: 0 4px;
    }

    :global(.HTTPStatus) {
      padding: 0;
    }

    :global(.Avatar) {
      flex-shrink: 0;
    }
  }
}
