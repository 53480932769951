.Content {
  &-layout {
    // Background needed for backdrop filter blur to work within this container, 
    // because this container's ancestor has an opacity animation.
    background-color: var(--SuperHub-background); 
    border-top: 1px solid var(--color-border-default);
    display: grid;
    grid-template-columns: minmax(220px, auto) 1fr;
    height: calc(100vh - var(--SuperHub-top-nav-height) );
    margin: 0 auto;
    max-width: var(--container-lg);
    width: 100%;
  }

  &-sidebar-link {
    gap: var(--xs);
  }
}
