.VersionsPanel {
  box-sizing: border-box;
  display: grid;
  gap: var(--sm);
  grid-template-rows: 1fr auto;
  height: calc(100vh - var(--SuperHub-top-nav-height));
  left: 0;
  padding: var(--sm);
  position: absolute;
  top: var(--SuperHub-top-nav-height);
  width: var(--SuperHub-aside-width);
}
