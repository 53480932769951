@import '@core/styles/vars';

.MetricsPageShortcuts {
  &-label {
    color: var(--gray50);
    font-size: 11px;
    font-weight: var(--font-weight);
    text-transform: uppercase;
  }

  :global(.Button_sm) {
    --button-font-size: 12px;

    height: 28px;
    padding: 0 8px;
  }
}
