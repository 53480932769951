.Content {
  &-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    height: 100%;
    min-height: 0;
    overflow: hidden;
    width: 100%;
  }

  &-form-wrapper {
    height: 100%;
    min-height: 0;
  }
}

.StartScreen {  
  &-create-btn {
    backdrop-filter: blur(2.5px);
    background: rgba(var(--gray0-rgb), 0.80);
    border: 1px solid var(--color-border-default);
    border-radius: 12px;
    height: 100%;
    padding: var(--lg);
    width: 250px;

    &:hover {
      background: var(--gray0);
    }

    &_circle {
      background: rgba(255, 255, 255, 0.08);
      border: 2px dashed var(--gray80);
      border-radius: var(--lg);
      display: flex;
      margin: var(--xs);
      padding: var(--sm);
      transition: transform 1s var(--transition-timing);
    }

    &:hover &_circle {
      transform: scale(1.1);
      transition: transform 1s var(--transition-timing);
    }

    &_h1 {
      margin-top: var(--sm);
    }

    &_h2 {
      font-size: 14px;
      font-weight: var(--font-weight-normal);
      line-height: 1.4;
      text-align: center;
      white-space: normal;
    }
  }

  &-marketplace {
    font-size: 14px;
    line-height: 1.5;
    overflow: hidden;
    padding: 0 var(--lg);
    text-align: center;
    width: 400px;

    &-btn {
      z-index: 1;
    }

    &-stickers {
      gap: 125px;
      position: absolute;

      img {
        transform: scale(0.5);
      }
    }
  }
}
