@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.MetricsFilter {
  &-User {
    margin-left: 0.6em;
  }

  &-Reset {
    color: var(--gray40);
    font-size: 12px;
    font-weight: 600;
    padding: 0 8px;
    text-transform: uppercase;

    &-icon {
      color: var(--gray70);
      font-weight: 600;
    }
  }

  &-FilterGroup,
  &-GraphType,
  &-Method,
  &-Status {
    &-btn-dark-on-black {
      @include dark-mode {
        background: #{rgba(white, 0.05)};
      }
    }
  }
}
