.PageContent {
  box-sizing: border-box;
  color: var(--white);
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: calc(min(100%, 800px));
  height: fit-content;
  justify-content: center;
  padding: 64px 30px;

  &_fullWidth {
    grid-template-columns: 100%;
    padding: 30px;
  }

  &_condensed {
    padding: var(--sm) var(--SuperHub-margin) 30px;
  }
}
