@import '@core/styles/vars';

.SidebarNavGroup {
  border-bottom: 1px solid var(--color-border-default);
  padding-bottom: var(--sm);

  &:last-child {
    border-bottom: 0;
  }

  &-button {
    background: none;
    border: 0;
    border-radius: var(--border-radius);
    color: var(--color-text-minimum);
    cursor: pointer;
    font: inherit;
    font-size: 12px;
    font-weight: var(--font-weight);
    padding: var(--xs);
    text-transform: uppercase;
    user-select: none;

    &:hover {
      background: var(--color-border-default);
    }
  }

  &-content-wrapper {
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-content {
    height: 100%;
    overflow: hidden auto;
    visibility: visible;

    &[hidden] {
      overflow: visible;
      transition: visibility 0s linear var(--transition-slow);
      visibility: hidden;
    }
  }

  &-back-nav {
    border-bottom: 1px solid var(--color-border-default);
    padding-bottom: var(--sm);
    width: 100%;
  }
}
