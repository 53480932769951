/* stylelint-disable */
.DashMiniSidebar {
  $duration: 400ms;
  $width: 56px;

  &_mini-sidebar {
    :global {
      // Styles when sidebar is expanded and being interacted with.
      #sidebar-view {
        transition: width $duration;

        ~ div#main {
          opacity: 1; // This overrides the loading opacity change.
          transition-duration: $duration;
          transition-property: margin, width;
        }

        #staging-dropdown-parent #staging-dropdown,
        #sidebar #sidebars #link-container > div,
        header #header-project-name,
        h2.divider-text span,
        #sidebar #sidebars #sidebar-main .links .link span {
          transition: opacity $duration;
        }

        header .logo-link .logo {
          transition-duration: $duration;
          transition-property: transform, width;
        }

        #sidebar #sidebars #sidebar-other {
          opacity: 0;
        }
      }

      // Styles when sidebar is collapsed and not being interacted with.
      #sidebar-view:not(:hover) {
        width: $width;
        overflow: hidden;

        ~ div#main {
          margin-left: $width;
          width: calc(100% - #{$width});
        }

        #staging-dropdown-parent #staging-dropdown,
        #sidebar #sidebars #link-container > div,
        header #header-project-name,
        h2.divider-text span,
        #sidebar #sidebars #sidebar-main .links .link span {
          opacity: 0;
        }

        header .logo-link .logo {
          transform: translateX(-6px);
          width: 30px;
        }

        #quickswitcher-opener {
          width: $width;
        }
      }
    }
  }
}
