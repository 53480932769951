.WebhookVariablesSections {  
  &-container {
    margin-bottom: var(--md);
  }

  &-title {
    font-size: 11px;
    text-transform: uppercase;
  }
}
