.CustomBlockManagement {
  background-color: var(--gray100);
  display: grid;
  grid-auto-rows: auto 1fr;
  height: 100vh;

  &_disabled {
    pointer-events: none;
  }

  &_loading {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &-container {
    height: 100%;
    min-height: 0;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &-main {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 0;
    padding: 0;
  }

  &-new-btn:focus {
    color: #fff; // override global dash link focus color
  }
}
