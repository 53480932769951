/* stylelint-disable */
@import '@core/styles/mixins/dark-mode';
@import '@core/styles/mixins/apply-as-global';

// Set @readme/markdown overrides where we render markdown
// $global param: Optionally apply styles globally with the :global selector
@mixin markdown-overrides($global: false) {
  --markdown-font: var(--font-family);
  --markdown-font-size: 15px;
  --markdown-line-height: 1.5;
  --markdown-title-font: var(--font-family);
  --markdown-title-weight: var(--font-weight-bold);

  // --table-edges uses hex equivalent of --color-border-default over --color-page-bg
  // to prevent transparent border issues in safari
  --table-edges: #e5e5e5;
  --table-head: var(--gray100);
  --table-head-text: var(--color-text-default);
  --table-stripe: var(--gray100);
  --table-text: var(--color-text-default);
  --table-row: var(--color-bg-page);

  @include dark-mode {
    // --table-edges uses hex equivalent of --color-border-default over --color-page-bg
    // to prevent transparent border issues in safari
    --table-edges: #3a4348;
    --table-head: var(--gray15);
    --table-stripe: var(--gray15);
    --md-code-background: var(--gray15);
    --md-code-tabs: var(--gray20);
    --md-code-text: var(--color-text-default);

    @include apply-as-global($global) {
      .markdown-body {
        blockquote:not(.callout) {
          border-color: var(--gray20);
          color: var(--color-text-minimum);
        }
  
        .lightbox.open {
          background-color: rgba(var(--color-bg-page-rgb), 0.95);
        }
  
        hr {
          border-bottom: 1px solid var(--color-border-default);
        }
  
        .task-list-item input[type='checkbox'] {
          color-scheme: dark;
        }
      }
  
      .callout.callout_default {
        --background: rgba(var(--gray100-rgb), 0.05);
      }
  
      .callout.callout_info {
        --background: rgba(var(--blue-rgb), 0.05);
      }
  
      .callout.callout_ok,
      .callout.callout_okay,
      .callout.callout_success {
        --background: rgba(var(--green-rgb), 0.05);
      }
  
      .callout.callout_warn,
      .callout.callout_warning {
        --background: rgba(var(--yellow-rgb), 0.05);
      }
  
      .callout.callout_err,
      .callout.callout_error {
        --background: rgba(var(--red-rgb), 0.05);
      }
    } 
  }
}
