.RenameVersionModal {
  &-header {
    border: 0;
    margin: var(--md) var(--md) var(--sm);
  }

  &-title {
    color: var(--color-text-default);
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    line-height: 1.8;
    margin: 0;
    text-align: center;
  }

  &-body {
    color: var(--color-text-muted);
    font-size: 16px;
    line-height: 1.4;
    margin: 0 var(--md) var(--md);
    text-align: center;
  }
}
