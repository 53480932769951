.SetupPersonalizedDocs {
  &-setup-btns {
    width: 100%;
  }

  &-setup-btn {
    height: 31px;
    justify-content: flex-start;
    width: 100%;
  }

  &-key-graphic {
    align-items: center;
    backdrop-filter: blur(2px);
    background: linear-gradient(180deg, rgba(195, 239, 218, 0.1) 0%, rgba(112, 137, 125, 0.1) 100%);
    border-radius: 17.5px;
    box-shadow:
      inset 0 0 0 1px #096449,
      0 0 10px rgba(18, 202, 147, 0.15),
      0 4px 0 #096449;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 66px;
    justify-content: center;
    padding: 10px;
    width: 66px;

    img {
      height: 42px;
    }
  }
  
  &-instructions {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &-subtext {
    color: var(--color-text-muted);
    margin-top: -36px;
    opacity: .5;
  }

  &-enterprise {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;  
  }


}
