.WelcomeModal {
    overflow: visible;
    width: 400px; 

    &-overflow-visible {
      overflow: visible;
    }

    &-container {
      background: var(--purple30);
      border-radius: 7px;
      color: var(--white);
      font-family: var(--font-family);
    }

    &-body {
      padding: var(--md);
      padding-top: 0;
    }

    &-footer {
      border-top: 1px solid rgba(var(--white-rgb), 0.1);
      display: flex;
      justify-content: center;
      padding: var(--md);
    }

    &-image-wrapper {
      // adds necessary vertical space behind absolute positioned image
      height: 125px;
      position: relative;
        
      img {
        height: 145px;
        left: 50%;
        padding-right: 42px; // visually centers Owlbert
        position: absolute;
        top: -15px; // moves Owlbert's ears outside modal
        transform: translateX(-50%);
      }
    }

    &-title {
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }
    
    &-text {
      line-height: 24px;
      text-align: center;    
    }
    
    &-link {
      align-items: center;
      color: inherit;
      display: inline-flex;
      text-decoration: underline;

      &:hover {
        color: var(--purple90);
      }
    }

}

