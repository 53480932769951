.Integrations {
  &-details {
    button {
      font-size: 12px;
      margin-bottom: var(--sm);
    }

    :global(.FormGroup + .FormGroup) {
      margin-top: var(--xs);
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--sm);
      padding: var(--sm);
    }
  }
}
