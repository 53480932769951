.BranchStatusIndicator {
  align-items: center;
  background-color: rgba(var(--green50-rgb), 0.25);
  border-radius: var(--border-radius);
  display: flex;
  font-size: 9px;
  line-height: 1;
  margin: 0 2px;
  padding: 3px 4px;
  text-transform: uppercase;

  &::before {
    background-color: var(--green);
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    margin-right: var(--xs);
    width: 8px;
  }

  &_minimal {
    align-items: center;
    background: none;
    height: var(--icon-sm);
    justify-content: center;
    margin: 0;
    padding: 0;
    width: var(--icon-sm);

    &::before {
      margin: 0;
    }
  }
}
