@import '@core/styles/vars';

.BaseNav {
  // Distance from the left or right edge of a var(--container-lg) width container to the edge of the viewport.
  // This is used as a base value to center the nav bar over the hub content container.
  --BaseNav-inline-offset: max(0px, (100vw / 2) - (var(--container-lg) / 2));

  // Half the width of the aside nav, used to offset the left and right edge of the nav bar in the side-by-side view.
  --BaseNav-aside-offset: calc(var(--SuperHub-aside-width) / 2);

  left: var(--BaseNav-inline-offset);
  padding: 0 var(--SuperHub-margin);
  position: absolute;
  right: var(--BaseNav-inline-offset);
  transition: left var(--transition-slow) var(--transition-timing), right var(--transition-slow) var(--transition-timing);


  &_aside {
    // The math for the positioning here gets the left & right edge of the nav bar to follow the content container of
    // the hub in the side-by-side view.
    left: max(0px, calc(var(--BaseNav-inline-offset) - var(--BaseNav-aside-offset)));
    right: max(var(--BaseNav-inline-offset) + var(--BaseNav-aside-offset), var(--SuperHub-aside-width));
  }

  &_preview {
    // The math for the positioning here gets the left edge of the nav bar to follow the content container of the hub
    // in the side-by-side view.
    left: max(0px, calc(var(--BaseNav-inline-offset) - var(--BaseNav-aside-offset)));

    // We want the settings nav "Back to Docs" button to go all the way right in the settings-preview layout.
    // The layout is more balanced this way.
    right: 0;
  }

  &_versions {
    // The math for the positioning here gets the left & right edge of the nav bar to follow the content container of
    // the hub in the versions view.
    left: max(var(--BaseNav-inline-offset) + var(--BaseNav-aside-offset), var(--SuperHub-aside-width));
    right: max(0px, calc(var(--BaseNav-inline-offset) - var(--BaseNav-aside-offset)));

    @media (max-width: $container-lg) {
      left: 0;
    }
  }
}
