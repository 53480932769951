.FormError {
  &-error-message {
    align-items: center;
    color: var(--red);
    padding: var(--xs) 0;

    &-heading {
      align-items: center;
      display: flex;
      font-size: 13px;
      font-weight: var(--font-weight);
      gap: var(--xs);
      justify-content: center;
      margin: 0;
    }

    &-icon {
      --icon-stroke-width: 2.5px !important;
    }

    &-description {
      font-size: 12px;
      margin: 3px 0 0;
    }

    code {
      background-color: rgba(var(--red-rgb), 0.1);
      font-size: 12px;
      padding: 0 2px;
    }
  }
}