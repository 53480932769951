.Fieldset {
  --Fieldset-gap: var(--lg);

  $block: &;

  border: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-width: 0;
  padding: 0;

  &-legend {
    color: var(--color-text-default);

    // Setting float to not 'none' will remove browser default "rendered legend" layout
    // https://html.spec.whatwg.org/multipage/rendering.html#rendered-legend
    // and legend will appear normally in the page flow.
    float: left;
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: 18px;
    margin-bottom: 30px;

    a {
      &, &:hover {
        color: currentColor;
        text-decoration: underline;
      }
    }

    &-button {
      align-items: center;
      background: none;
      border: 0;
      color: inherit;
      cursor: pointer;
      display: flex;
      font: inherit;
      gap: var(--xs);
      line-height: normal;
      padding: 0;
      position: relative;
    }

    &-icon {
      transition: transform var(--transition-fast);

      &_open {
        transform: rotate(180deg);
      }
    }

    &-hr {
      border: 0;
      border-top: 1px solid var(--color-border-default);
      width: 100%;
    }
  }

  &_sm {
    --Fieldset-gap: 25px;

    #{$block}-legend {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }

  + #{$block} {
    margin-top: var(--Fieldset-gap);
    padding-top: var(--Fieldset-gap);
    position: relative;

    &::before {
      background-color: var(--color-border-default);
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  + #{$block}_no-legend {
    border-top: 1px solid var(--color-border-default);
    padding-top: var(--lg);
  }
}
