.InstallSDKStep {

  &-container {
    width: 600px;
  }

  &-separator {
    background-color: var(--color-border-default);
    height: 1px;
    margin: 15px 0;
    width: 100%;
  }

  &-title { 
    align-items: center;
    display: flex;
    gap: var(--sm);
    margin: 0 0 15px;
  }

  &-instructionsContainer {
    color: var(--color-text-muted);
    font-size: 13px;
    font-weight: var(--font-weight-normal);
    line-height: 19px;
  }
}
