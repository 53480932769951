.EditVersionForm {
  background: var(--gray0);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  padding: var(--sm) 0;

  &-forked-from {
    font-size: 10px;
  }
}
