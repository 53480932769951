.UnversionedWarning {
  cursor: help;
  padding-left: var(--xs);
  
  &-label {
    font-size: 13px;
    line-height: 1.3;
    text-wrap: balance;
  }

  &-icon {
    opacity: 0.5;
  }
  
  &-tooltip {
    max-width: 230px;
  
    &-description {
      font-size: 13px;
      line-height: 1.3;
      margin: 0;
      text-align: center;
    }
  }
}
