.PlanAccessBanner {
  border-radius: var(--border-radius);
  padding: 15px;
  text-align: left;

  &-description {
    font-size: 14px;
    font-weight: var(--font-weight-normal);
    line-height: 1.4;
    margin: 0 0 var(--sm);
    white-space: normal;
  }
}
