.DemoGate {
  min-width: 0;
  width: 100%;
  
  &-content {
    transition: opacity var(--transition);

    &:global([inert=""]) {
      opacity: 0.5; 
    }
  }

  &-banner {
    border-radius: var(--border-radius-lg);
    margin-bottom: 15px;
    padding: 15px;

    &_condensed {
      padding: var(--sm);
    }
  }

  &-body {
    padding-top: 5px
  }

  &-title,
  &-description {
    margin: 0;
    text-align: center;
  }

  &-title {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    line-height: 1.4;
  }

  &-description {
    color: var(--purple90);
    font-size: 13px;
    line-height: 1.4;
    margin-top: 2px;
  }
 
  &-footer {
    margin-top: 15px;
  }
}
