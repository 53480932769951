.CopyCode {
  &-copy-code {
    align-items: center;
    background-color: var(--gray10);
    border: 1px solid rgba(white, 0.1);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    color: var(--white);
    display: grid;
    -webkit-font-smoothing: antialiased;
    gap: var(--sm);
    grid-template-columns: 1fr auto;
    margin-bottom: var(--sm);
    padding: var(--xs) var(--sm);
  

    &_multiline {
      align-items: flex-start;

      code {
        overflow: auto;
        white-space: pre;
      }

      &::before {
        height: 100%;
      }
    }

    &-muted {
      color: var(--gray60);
    }

    &-btn {
      border: 0;
      color: var(--gray60);

      &:not(:disabled):hover,
      &:not(:disabled):focus-visible {
        color: var(--gray70);
      }
    }
  }
}