.PageUsageMenu {
  &-label {
    font-size: 10px;
    font-weight: var(--font-weight);
    height: auto;
    letter-spacing: normal;
    padding: var(--xs) var(--sm);
    text-transform: uppercase;
    width: auto;
  }

  &-menu {
    width: 250px;

    &-search {
      margin: var(--xs) var(--sm);

      &-input {
        width: 100%;
      }
    }

    &-results {
      max-height: 110px;
      overflow: hidden scroll;

      &-label {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 0;
      }

      &-name {
        overflow: hidden;
        padding-right: var(--xs);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-current {
        color: var(--Menu-description-color);
        font-size: 11px;
        font-weight: var(--font-weight-bold);
        line-height: 1.2;
        margin-left: var(--xs);
        white-space: nowrap;
      }

      &-version {
        color: var(--Menu-description-color);
        font-size: 12px;
        font-weight: var(--font-weight-normal);
        line-height: 1.2;
        margin-left: var(--xs);
      }

      &_empty {
        color: var(--color-text-minimum);
        display: flex;
        font-size: 12px;
        justify-content: center;
        margin-top: var(--sm);
      }
    }

    &-note {
      color: var(--color-text-minimum);
      font-size: 12px;
      line-height: 15px;
    }

    :global {
      // Global dash style we don't want
      a:focus {
        color: unset;
      }

      .Menu-item-external-link-icon {
        display: none;
      }
    }
  }

  &-loading-spinner {
    color: var(--color-text-minimum-icon);
    font-size: 10px;
    padding: var(--xs) var(--sm);
  }
}
