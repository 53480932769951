.MyDevelopers {
  --MyDevelopers-padding: 15px;

  &-panel-root {
    overflow-y: auto;
  }

  &-layout {
    $block: &;

    background: var(--SuperHub-background);
    border-top: 1px solid var(--gray10);
    border-top-left-radius: var(--SuperHub-border-radius);
    border-top-right-radius: var(--SuperHub-border-radius);
    bottom: 0;
    height: var(--MyDevelopers-min-content-height) !important;
    left: 0;
    margin: 0 var(--SuperHub-margin);
    padding: var(--MyDevelopers-padding);
    position: fixed;
    right: 0;
    transform-origin: center center;
    transition-duration: var(--transition-slow);
    transition-property: height;
    transition-timing-function: var(--transition-timing);
    width: auto;

    &_expanded {
      border-radius: 0;
      height: calc(100% - var(--SuperHub-top-nav-height) - (var(--MyDevelopers-padding) * 2)) !important;

      &#{$block}_with-bottom-bar {
        height: calc(100% - var(--MyDevelopers-bottom-bar-height) - var(--SuperHub-top-nav-height) - (var(--MyDevelopers-padding))) !important;
      }
    }

    &_with-bottom-bar {
      bottom: var(--MyDevelopers-bottom-bar-height);
      padding-bottom: 0;
    }

    &_setup {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(var(--green40-rgb), 0.2) 100%), #000;
      margin: 0;
    }
  }

  &-container {
    height: 100%;
    margin: 0 auto;
    max-width: calc(var(--container-lg) - (var(--SuperHub-margin) * 2) - (var(--MyDevelopers-padding) * 2));
  }
}
