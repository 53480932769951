.SidebarNav {
  border-right: 1px solid var(--color-border-default);
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  min-height: 0;


  &_loading,
  &_empty {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;

    :global(.IconWrapper) {
      vertical-align: middle;
    }
  }

  &_withBanner {
    grid-template-rows: auto 1fr auto;
  }


  &-list {
    min-height: 0;
    padding: var(--xs);
  }

  &-banner {
    padding: var(--sm);
  }
}
